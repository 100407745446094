import React, { useState } from "react";
import styled, { css } from 'styled-components';
import { Modal } from "../../components/Modal/Modal";
import { Media } from "../../styles";
import toucanIcon from "../../assets/toucan-icon.png"

interface NFTCheckModalProps {
    dataTestId?: string,
    visible: boolean,
    setVisible: (visible : boolean) => void,
    setTradeDisabled: (disabled: boolean) => void,
    setNextVisible: (visible : boolean) => void,
}

export const NFTCheckModal = ({
  dataTestId,
  visible,
  setVisible,
  setTradeDisabled,
  setNextVisible
}: NFTCheckModalProps) => {

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  }

  return (
    <StyledModal dataTestId={`${dataTestId}-${visible}`} isVisible={visible} onClose={() => setVisible(false)}>
        <Title data-testid={dataTestId + "-title"}>Warning</Title>
        <ToucanIcon src={toucanIcon}/>
        <Text>You are trading your NFTs, you may not be able to get the same ones back</Text>
        <CheckboxLabel>
            <CheckboxInput data-testid="nft-checkbox" type="checkbox" required onChange={handleCheckboxChange} />
            <CheckboxText>I acknowledge the risk associated with this trade</CheckboxText>
        </CheckboxLabel>
        <ActionButton data-testid={`continue-btn-nft-check-modal-${isChecked}`} disabled={!isChecked} onClick={() => {
            setVisible(false)
            setNextVisible(true)
            setIsChecked(false)
        }}>Continue</ActionButton>
        <ModalButton onClick={() => {
            setTradeDisabled(false)
            setVisible(false)
        }}>{`Never mind \u2192`}</ModalButton>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
    color: #00BDFF;
    height: 524px;
    padding-bottom: 0;

    svg {
        margin: 24px auto 24px;
        height: 128px;
        width: 128px;
    }

    ${Media.mobile} {
        height: 85%;
        max-width: 100%;
        margin-top: 15.5%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    }

`

const Title = styled.h3`
  margin-top: 12px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const ToucanIcon = styled.img`
    width: 128px;
    margin: 20px auto;
`

const PriceImpact = styled(Title)<{value: number }>`

  margin-top: 12px;
  margin-bottom: 12px;

  ${({ value }) => value <= -5 && css`
    color: #FF5349;
  `};
  ${({ value }) => value > -5 && value <= -1  && css`
    color: #DAA520;  
  `};
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  margin-top: 12px;
`;

const CheckboxLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;
`;

const CheckboxInput = styled.input`
  margin-right: 8px;
  appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #7d7d97;
  border-radius: 4px;
  cursor: pointer;

  &:checked {
    background-color: #00BDFF;
    border-color: #00BDFF;
  }

  &:checked::after {
    content: "\\2714";
    font-size: 16px;
    font-weight: bold;
    color: white;
    position: absolute;
    margin-top: 1px;
    left: 23px;
  }
`;

const CheckboxText = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  color: #7d7d97;
`;

const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 264px;
  height: 76px;
  margin: 12px auto 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #0a0e27;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;

  svg {
    height: 28px;
    width: 28px;
    margin-right: 8px;
    color: #464659;
  }

  &:not(:disabled) svg path {
    fill: #151530;
  }

  &:disabled {
    background: #0a0e27;
    color: #464659;
    border: solid 2px #464659;
  }
`;

const ModalButton = styled.button`
  margin-top: 8px;
  font-weight: 100;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #7D7D97;
  white-space: nowrap;
  letter-spacing: -0.02em;

  :hover {
      color: #00BDFF;
  }
`;