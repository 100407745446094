import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import chevronDown from "@/assets/icons/chevron-down.svg";
import blueCheck from "@/assets/icons/blue-check.svg";
import { useClickOutside } from "@/hooks/useClickOutside";

type DropdownProps = {
  options: string[];
  onOptionChange: (selectedOption: string) => void;
};

export const CustomDropdown: React.FC<DropdownProps> = ({ options, onOptionChange }: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<string | null>('all');
  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  const handleOptionChange = (option: string) => {
    onOptionChange(option);
    setSelectedOption(option);
    setIsOpen(false);
  };

  useClickOutside(ref, () => setIsOpen(false));

  return (
    <DropdownStyled ref={ref}>
    <SelectInput onClick={() => setIsOpen(!isOpen)}>
      {selectedOption === "all" ? "All LP Tokens" : selectedOption}
      <ChevronImg alt="chevron" src={chevronDown} isOpen={isOpen} />
    </SelectInput>
    {isOpen && (
      <OptionList>
        <Option selected={selectedOption === 'all'} onClick={() => handleOptionChange('all')}>All LP Tokens</Option>
        {options.map((option) => (
          <Option selected={selectedOption === option} key={option} onClick={() => handleOptionChange(option)}>
            {option}
          </Option>
        ))}
      </OptionList>
    )}
  </DropdownStyled>
  );
};

const DropdownStyled = styled.div`
min-width: 153px;
  position: relative;
  cursor: pointer;
  `;

const SelectInput = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
  padding: 8px 12px;
  border-radius: 8px;
background: var(--dark-2, #171B33);
outline: none;
border: none;
white-space: nowrap;
color: #FFF;
text-align: center;

/* Text 3/Medium */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 140%; /* 19.6px */;
  `;

  const ChevronImg = styled.img<{isOpen: boolean}>`
  transform: ${({isOpen}) => isOpen ? "rotate(180deg)" : "none"};
  transition: all 0.15s ease-in-out;
  `;

const OptionList = styled.ul`
position: absolute;
top: 44px;
left: 0;

border-radius: 8px;
background: var(--dark-2, #171B33);
box-shadow: 0px 8px 24px 0px rgba(11, 13, 34, 0.60);
padding: 8px 6px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
display: flex;
width: 100%;
`;

const Option = styled.li<{selected?: boolean}>`
padding: 8px 6px;
width: 100%;
border-radius: 6px;
display: flex;
align-items: center;
justify-content: space-between;

&:hover {
  background: #1E2239;
  cursor: pointer;
}

&::after {
  content: ${({selected}) => selected ? `url(${blueCheck})` : ""};
}
`;
