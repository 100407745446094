import { createSlice } from '@reduxjs/toolkit'
interface BalancesState {
    balances: Record<string, string>
    nftBalances: Record<string, any[]>
}

const initialState : BalancesState = {balances: {}, nftBalances: {}}

const balancesSlice = createSlice({
  name: 'balances',
  initialState,
  reducers: {
      addBalance: (state, action) => {
          let newBalances = {...state.balances}
          newBalances[action.payload.address] = action.payload.amount
          return {
              ...state,
              balances: newBalances
          }
      },
      addNFTBalance: (state, action) => {
        let newNFTBalances = {...state.nftBalances}
        newNFTBalances[action.payload.collection] = action.payload.items
        return {
            ...state,
            nftBalances: newNFTBalances
        }
      },
      clearBalances: () => {
          return initialState
      }
  }
})

export const { addBalance, addNFTBalance, clearBalances } = balancesSlice.actions

export default balancesSlice.reducer