/* Type definitions, constants, and interfaces */

import { subDays, subMonths } from "date-fns";

export type InputData = {
  date: number;
  tokens: Record<string, number>;
  totalTvl?: number;
};

interface AssetFilter {
  label: string;
  value: "All" | "Token" | "NFT";
}

interface SeasonFilter {
  label: string;
  value: "All" | "Season 1" | "Season 2" | "Season 3" | "Season 4";
}

export interface DateFilter {
  label: "7d" | "1m" | "3m" | "All";
  period: "day" | "week" | "month" | "all";
  dateFormat?: string;
  days: number | null;
  getStart: ((current: Date) => Date) | null;
  numberOfIntervals: number | null;
}

export const dateFilters: DateFilter[] = [
  {
    label: "7d",
    period: "day",
    dateFormat: "dd.MM",
    days: 7,
    getStart: (current : Date) => subDays(current, 7),
    numberOfIntervals: 6,
  },
  {
    label: "1m",
    period: "week",
    dateFormat: "dd.MM",
    getStart: (current : Date) => subDays(current, 28),
    days: 30,
    numberOfIntervals: 4,
  },
  {
    label: "3m",
    period: "month",
    dateFormat: "MM.yyyy",
    getStart: (current : Date) => subMonths(current, 3),
    days: 90,
    numberOfIntervals: 3,
  },
  {
    label: "All",
    period: "all",
    dateFormat: "MM.yyyy",
    getStart: null,
    days: null,
    numberOfIntervals: null,
  },
];

export const assetFilters: AssetFilter[] = [
  {
    label: "All Assets",
    value: "All",
  },
  {
    label: "Tokens",
    value: "Token",
  },
  {
    label: "NFTs",
    value: "NFT",
  },
];

export const seasonFilters: SeasonFilter[] = [
  {
    label: "Season One",
    value: "Season 1",
  },
  {
    label: "Season Two",
    value: "Season 2",
  },
  {
    label: "Season Three",
    value: "Season 3",
  },
  {
    label: "Season Four",
    value: "Season 4",
  }
];

export const CURRENT_SEASON = seasonFilters.at(-1)!.label;

export const convertToSlug = (str : string) => { // replaces spaces with dashes, makes lowercase
    const slug = str.toLowerCase().replace(/\s+/g, '-');
    return slug;
}

export const unslug = (slug: string) => {
  const words = slug.replace(/-/g, ' ').split(' ');

  const capitalizedWords = words.map(word => 
      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );

  return capitalizedWords.join(' ');
}

export const filterColors : {[filter: string]: string} = {
    'Tokens': '#00BDFF',
    'NFTs': '#37dcf2'
}