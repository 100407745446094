import { calculateWrappedTokenId } from "../utils/ocean/utils";
import { nftCollections } from "../placeholders/tokens";

const WRAPPED_NFT_MAP : any = {}
const NFT_RARITY : {[address: string] : {[id: string] : number}} = {}

nftCollections.forEach((collection) => {
    if(collection.wrapped){
        const rarityTable = collection.is1155 ? [] : require(`./nftRarities/${collection.symbol.toLowerCase().substring(2)}-rarity.json`)
        NFT_RARITY[collection.address] = {}
        const idMap : any = {}
        for(let i = 0; i < collection.maxSupply!; i++){
            idMap[calculateWrappedTokenId(collection.address, i)] = [i, collection.symbol]
            NFT_RARITY[collection.address][i.toString()] = rarityTable[i] ?? null
        }
        WRAPPED_NFT_MAP[collection.symbol] = idMap
    }
})

export { WRAPPED_NFT_MAP, NFT_RARITY }
