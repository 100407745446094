export const tokenColors: Record<string, string> = {
  tWBTC: '#F09242',
  ETH: '#687ce3',
  tUSDC: '#3E73C4'
};

const tokenNames = Object.keys(tokenColors)

tokenNames.forEach((name) => {
    tokenColors['sh' + name] = tokenColors[name]
})

tokenColors['TOUCOIN'] = '#D6B974'
tokenColors['SMOLCOIN'] = '#7752DE'
tokenColors['lbp'] = '#00BDFF'
tokenColors['veSHELL'] = '#FFFFFF'
