import React from "react";
import styled from "styled-components";
import { Media } from "../../styles";

interface ItemProps {
  dataTestId?: string;
  icon: string;
  title: string;
  subtitle?: string;
  aboveTitle?: string;
  selected?: boolean;
  onClick: () => void;
}

export const CollectionGridItem = ({
  dataTestId,
  icon,
  title,
  subtitle,
  aboveTitle,
  selected,
  onClick,
}: ItemProps) => {
  return (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick}>
      <ImageContainer>
        <Logo src={icon} alt="Logo" />
      </ImageContainer>
      <AboveTitle>{aboveTitle}</AboveTitle>
      <Title>{title}</Title>
      <SubTitle>{subtitle}</SubTitle>
    </Button>
  );
};

const Button = styled.button<{ selected: boolean | undefined }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 12px;
  background: ${(props) =>
    props.selected
      ? "linear-gradient(#171B33, #171B33) padding-box, linear-gradient(to right, #37dcf2, #07c0fb) border-box"
      : "#171B33"};
  border: 1px solid transparent;
  border-radius: 16px;

  &:hover,
  &:focus {
    box-shadow: 0px 19px 45px #04081c;
    border-color: transparent;
    outline: ${(props) => (props.selected ? "none" : "2px solid #2C5173")};
  }

  ${Media.mobile} {
    // max-width: 182px;
  }
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 12px;
  overflow: hidden;
`;

const ImageContainer = styled.div`
  display: flex;

  &:before {
    content: "";
    display: block;
    width: 0px;
    padding-top: 100%;
  }
`;

const AboveTitle = styled.p`
  margin-top: 12px;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  text-align: left;
  ${Media.mobile} {
    font-size: 12px;
  }
`;

const Title = styled.p`
  margin-top: 6px;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const SubTitle = styled.p`
  margin-top: 2px;
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;
