import React, { useRef } from "react";
import styled from "styled-components";
import { useClickOutside } from "../../hooks/useClickOutside";
import { useToggler } from "../../hooks/useToggler";
import {
  Dropdown,
  DropdownButton,
  DropdownContent,
  DropdownToggle,
} from "../Dropdown";
import { DropdownIconDown, DropdownIconUp } from "../Icons/DropdownIcon";
import checked from "../../assets/icons/dropdown-checked.svg";
import { orderByType, orderType } from "../../hooks/useNFTSort";

export interface NFTFilter {
  orderBy: orderByType;
  order: orderType;
  label: string;
}

interface NFTFilterDropdownProps {
  value: NFTFilter;
  onChange: (value: NFTFilter) => void;
  list: NFTFilter[];
  className?: string;
}

export const FilterDropdown = ({
  value,
  onChange,
  list,
  className,
}: NFTFilterDropdownProps) => {
  const { visible, toggle } = useToggler();
  const ref = useRef(null);
  useClickOutside(ref, () => toggle(false));

  return (
    <StyledDropdown ref={ref} className={visible ? "expanded" : ""}>
      <StyledDropdownToggle onClick={() => toggle()} className={className}>
        {value.label}
        {visible ? <DropdownIconUp /> : <DropdownIconDown />}
      </StyledDropdownToggle>
      {visible && (
        <StyledDropdownContent>
          {list.map((item) => (
            <li key={item.label}>
              <StyledDropdownButton
                onClick={() => {
                  onChange(item);
                  toggle(false);
                }}
                className={item.label === value.label ? "active" : ""}
              >
                {item.label}
              </StyledDropdownButton>
            </li>
          ))}
        </StyledDropdownContent>
      )}
    </StyledDropdown>
  );
};

const StyledDropdownToggle = styled(DropdownToggle)`
  height: 36px;
  padding: 9px 28px 9px 12px;
  background-color: #171B33;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 17px;
`;

const StyledDropdown = styled(Dropdown)`
  width: 180px;
  flex-shrink: 0;

  &.expanded ${StyledDropdownToggle} {
    border: none;
  }
`;

const StyledDropdownContent = styled(DropdownContent)`
  padding: 11px 5px 11px 5px;
  background-color: #171B33;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 8px 24px rgba(11, 13, 34, 0.6);
`;

const StyledDropdownButton = styled(DropdownButton)`
  position: relative;
  padding: 9px 6px;
  font-size: 14px;
  line-height: 17px;
  color: #fff;

  &:after {
    display: none;
    position: absolute;
    content: url(${checked});
    right: 6px;
    top: 10px;
  }

  &:hover {
    background-color: #1E2239;
    border-radius: 6px;
  }

  &.active:after {
    display: block;
  }
`;
