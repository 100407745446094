import { 
    // BOOTY_ADDRESS,
    ETH_ADDRESS, 
    // SHELL_ADDRESS,
    // TOUCAN_ADDRESS
} from "../constants/addresses";

import ethIcon from "../assets/coins/ETH.svg";
import shellIcon from "../assets/coins/SHELL.svg"
import daiUsdcIcon from "../assets/coins/dai-usdc.svg";
import usdtUsdcIcon from "../assets/coins/usdt-usdc.svg";
import stablepoolIcon from "../assets/coins/stablepool.svg";
import ethUsdIcon from "../assets/coins/eth-usd.svg";
import wbtcUsdIcon from "../assets/coins/wbtc-usd.svg";
import wstethEthIcon from "../assets/coins/wsteth-eth.svg";
import arbEthIcon from "../assets/coins/arb-eth.svg";
import magicEthIcon from "../assets/coins/magic-eth.svg";
import stgEthIcon from "../assets/coins/stg-eth.svg";
import toucanIcon from "../assets/coins/TOUCAN.svg";
import shToucanIcon from "../assets/coins/shTOUCAN.svg";
import toucoinIcon from "../assets/coins/TOUCOIN.svg";
import toucoinEthIcon from "../assets/coins/toucoin-eth.svg"
import smolIcon from "../assets/coins/SMOL.png"
import shSmolIcon from "../assets/coins/shSMOL.png"
import smolcoinIcon from "../assets/coins/SMOLCOIN.png"
import smolpoolIcon from "../assets/coins/smolpool.png"
import countdownCratePoolIcon from "../assets/coins/countdown-crate-eth.svg"
import collabEthIcon from "../assets/coins/collab-eth.svg"
import lbpIcon from "../assets/coins/lbp.svg"
import shellEthIcon from "../assets/coins/shell-eth.svg";
import veShellCoin from "@/assets/icons/shell-white-coin.svg";

import accountantIcon from '../assets/booty/accountant-head.png'
import shAccountantIcon from '../assets/coins/shACCT.svg'
import { calculateWrappedTokenId } from "../utils/ocean/utils";
import placeholderIcon from "../assets/coins/logo-placeholder.svg"
import { itemsMap } from "@/pages/Booty/Items";

import crv2PoolIcon from '../assets/coins/2CRV.png'
import crvMim3PoolIcon from '../assets/coins/MIM3CRV.png'
import crvUSDUsdcIcon from '../assets/coins/crvUSD-USDC.png'
import crvUSDUsdtIcon from '../assets/coins/crvUSD-USDT.png'
import crvUSDFraxIcon from '../assets/coins/crvUSD-FRAX.png'
import crvUSDMimIcon from '../assets/coins/crvUSD-MIM.png'
import wstethWethBPTIcon from '../assets/coins/wstETH-WETH-BPT.png'
import rethWethBPTIcon from '../assets/coins/rETH-WETH-BPT.png'

import { ProtocolTypeOption, generateColorHex } from "@/components/DexBadge/DexBadge";
import { tokenColors } from "@/constants/tokenColors";

// @dev hexadecimal(ascii("Ether"))
export interface Token {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  oceanID?: string;
  icon: string;
  status?: string;
}

export interface VeToken {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  icon: string;
  status?: string;

}

export const veTokens: VeToken[] = [
  {
    name: "veSHELL",
    symbol: "veSHELL",
    address: "0x000",
    wrapped: false, 
    icon: veShellCoin
  },
]

export const tokens: Token[] = [
  {
    name: "Ether",
    symbol: "ETH",
    address: ETH_ADDRESS,
    wrapped: false,
    icon: ethIcon
  },
  {
    name: "USDC Test",
    symbol: "tUSDC",
    address: '0xda9C093a7D9e41d21Dc9A7ff5601A3FD02111d95',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48/logo.png'
  },
  {
    name: "Bitcoin Test",
    symbol: "tBTC",
    address: '0xcF749620571A3920a27CD8Eef17Daa1db7C93c4C',
    wrapped: false,
    icon: 'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599/logo.png'
  },
//   {
//     name: 'Shell',
//     symbol: 'SHELL',
//     address: SHELL_ADDRESS,
//     wrapped: false,
//     icon: shellIcon
//   },
//   {
//     name: "Fractional Toucans",
//     symbol: "TOUCOIN",
//     address: TOUCAN_ADDRESS,
//     wrapped: true,
//     oceanID: "0x7ae7c77f6f2a6e2dcb0629d48518736f6fa1632260407d1a3471091161e71fd2",
//     icon: toucoinIcon,
//   },
]

export interface ShellToken extends Token {
  oceanID: string;
  pool: string;
  tokens: string[];
  fee: number;
  tokenType?: ProtocolTypeOption;
  protocolVersion?: string;
}

export const shellTokens: ShellToken[] = [
//   {
//     name: 'DAI+USDC',
//     symbol: 'DAI+USDC',
//     address: '0',
//     oceanID: '0x3a761365b61dc1f8dff29cb0ded34582a9af82c6d7c95c050a0b79c4429f29ea',
//     wrapped: false,
//     icon: daiUsdcIcon,
//     pool: '0xEaE5B59499a461887fBf2BF47887e4e4cB91D703',
//     tokens: ['DAI', 'USDC.e'],
//     tokenType: "Shell",
//     protocolVersion: 'V3',
//     fee: 0.025
// },
// {
//     name: 'USDT+USDC',
//     symbol: 'USDT+USDC',
//     address: '0',
//     oceanID: '0x642f51c4088d172d44c33b10c4bffe4fed189bdb28bf15c18405580ef8eccec3',
//     wrapped: false,
//     icon: usdtUsdcIcon,
//     pool: '0xE290A897504313b46a1198Bd9b25b58E503842f7',
//     tokens: ['USDT', 'USDC.e'],
//     tokenType: "Shell",
//     protocolVersion: 'V3',
//     fee: 0.025
// },
// {
//     name: 'Stablepool',
//     symbol: 'Stablepool',
//     address: '0',
//     oceanID: '0x9f5c63fa8c8944d33da7fc39547f7b424a513e7b5e8c12f21cea26b58cfdd8f9',
//     wrapped: false,
//     icon: stablepoolIcon,
//     pool: '0x1c78820bE30c6013F5eaC98b3DdFbb3431e1Ad29',
//     tokens: ['USDT+USDC', 'DAI+USDC'],
//     tokenType: "Shell",
//     protocolVersion: 'V3',
//     fee: 0.025
// },
{
    name: 'tUSDC+ETH',
    symbol: 'tUSDC+ETH',
    address: '0',
    oceanID: '0xafb8cd62c5da781b9064c9a8894ffb7f0c2f29d236be6d8800a8fa0d470231f1',
    wrapped: true,
    icon: ethUsdIcon,
    pool: '0x006868413D48492Ed7f41EBB7A1BA87395227180',
    tokens: ['tUSDC', 'ETH'],
    tokenType: "Shell",
    protocolVersion: 'V3',
    fee: 0.25
},
{
    name: 'tUSDC+tBTC',
    symbol: 'tUSDC+tBTC',
    address: '0',
    oceanID: '0xcbd04edda089a7d0131d0f64e13df7d82f651e1a68cf3679b9f6ccee3adb3e97',
    wrapped: true,
    icon: wbtcUsdIcon,
    pool: '0x367889b69fA2c138827c2A3c829835F8866D07d4',
    tokens: ['tUSDC', 'tBTC'],
    tokenType: "Shell",
    protocolVersion: 'V3',
    fee: 0.25
},
// {
//     name: 'TOUCOIN+ETH',
//     symbol: 'TOUCOIN+ETH',
//     address: '0',
//     oceanID: '0x23552cde81bed5d4f982c67846e28cc42f73acfd6ef5408350dcc6c3ebe9c9a5',
//     wrapped: false,
//     icon: toucoinEthIcon,
//     pool: '0x4e9258D161fa454c7B6314527B00e21318b7FC40',
//     tokens: ['TOUCOIN', 'ETH'],
//     tokenType: "Shell",
//     protocolVersion: 'V3',
//     fee: 0.25
// },
];

export interface NFTCollection extends Token {
  name: string;
  symbol: string;
  address: string;
  wrapped: boolean;
  ipfsHash: string;
  fractionalizer?: string;
  maxSupply?: number;
  is1155?: boolean
  id1155?: number;
}

export const nftCollections: NFTCollection[] = [
//   {
//     name: "Government Toucans",
//     symbol: "TOUCAN",
//     address: TOUCAN_ADDRESS,
//     wrapped: false,
//     icon: toucanIcon,
//     ipfsHash: "QmNzwGJJEFNsck7havfqX7MjVRtiWpuAA7MWwc9kD8XHys",
//     fractionalizer: "0xCeFe4D3ee49D72560AB01ff1F0B6073f68Ca34e1",
//   },
//   {
//     name: "Wrapped Toucans",
//     symbol: "shTOUCAN",
//     address: TOUCAN_ADDRESS,
//     wrapped: true,
//     icon: shToucanIcon,
//     ipfsHash: "QmNzwGJJEFNsck7havfqX7MjVRtiWpuAA7MWwc9kD8XHys",
//     fractionalizer: "0xCeFe4D3ee49D72560AB01ff1F0B6073f68Ca34e1",
//     maxSupply: 47021,
//   },
//   {
//     name: "Accountant's Trove",
//     symbol: "ACCT",
//     address: BOOTY_ADDRESS,
//     wrapped: false,
//     icon: accountantIcon,
//     ipfsHash: "",
//     fractionalizer: "0xd44FDf2B894f25408244aC62650F98442B0a2518",
//     is1155: true,
//     maxSupply: itemsMap.length,
//   },
//   {
//     name: "Wrapped Trove",
//     symbol: "shACCT",
//     address: BOOTY_ADDRESS,
//     wrapped: true,
//     icon: shAccountantIcon,
//     ipfsHash: "",
//     fractionalizer: "0xd44FDf2B894f25408244aC62650F98442B0a2518",
//     is1155: true,
//     maxSupply: itemsMap.length,
//   },
];

export interface NFT {
  id: number;
  symbol: string;
  name: string;
  wrapped: boolean;
  rarity: number;
  image: string;
}

export interface LBPToken extends Token {
  description: string;
  maxSupply?: number;
  pairToken: string;
  poolName: string;
  poolAddress: string;
  poolOceanID: string;
  poolIcon: string;
}

export const lbpTokens: LBPToken[] = [
    // {
    //     name: 'Sunken Crate',
    //     symbol: 'frACCT-0',
    //     description: 'Sunken Crate',
    //     address: BOOTY_ADDRESS,
    //     wrapped: true,
    //     icon: '',
    //     pairToken: 'ETH',
    //     poolName: 'Sunken Crate Pool',
    //     poolAddress: '0x4E064dC73F1a14833B75c41172457cd09F67C4ae',
    //     poolOceanID: '0xdf9599ecff738d0b92d1829e18d0805d8265d7966002487f21f77a7b844b1572',
    //     poolIcon: collabEthIcon
    //   },
];

export interface ExternalToken extends Token {
    tokenType: ProtocolTypeOption;
    protocolVersion?: string;
}

export const externalTokens: ExternalToken[] = [];

export interface ExternalLPToken extends ExternalToken {
    pool: string;
    query: string;
    tokens: string[];
    fee: number;
    metadata?: any;
}

export const externalLPTokens: ExternalLPToken[] = [
    // {
    //   name: '2pool',
    //   symbol: '2CRV',
    //   address: '0xf02E1E866FD136408D6964DD1bceCc77877B4c43',
    //   wrapped: false,
    //   icon: crv2PoolIcon,
    //   pool: '0x75239F2b63616a52C94de74A1Ec6864913BF364B',
    //   query: '0xC6AB3Ec581f234B108c6f17664FB44216bbC2293',
    //   tokens: ['USDC.e', 'USDT'],
    //   tokenType: "Curve",
    //   fee: 0.01
    // },
]

export const veSHELL = {
    name: 'Voting Power',
    symbol: 'veSHELL'
}

export interface NFT1155 {
    id: number;
    name: string;
    image: string;
    balance: number;
    desc: string;
}

export function isShellToken(token: any): token is ShellToken {
  return (
    token &&
    "name" in token &&
    "icon" in token &&
    "oceanID" in token &&
    "pool" in token &&
    "tokens" in token
  );
}

export function isNFTCollection(token : any): token is NFTCollection {
    return (
        token &&
        "name" in token &&
        "symbol" in token &&
        "address" in token &&
        "wrapped" in token &&
        "ipfsHash" in token
    );
}

export function isLBPToken(token : any): token is LBPToken {
  return (
      token &&
    //   token.name.includes('lbp') &&
      "name" in token &&
      "description" in token &&
      "symbol" in token &&
      "address" in token &&
      "wrapped" in token &&
      "pairToken" in token
  );
}

export function isExternalToken(token : any): token is ExternalLPToken {
    return (
        token &&
        "name" in token &&
        "symbol" in token &&
        "address" in token &&
        "wrapped" in token &&
        "tokenType" in token &&
        !(token.tokenType == 'Shell' && token.protocolVersion == 'V3')
    );
  }

export function isExternalLPToken(token : any): token is ExternalLPToken {
  return (
      token &&
      "name" in token &&
      "symbol" in token &&
      "address" in token &&
      "wrapped" in token &&
      "pool" in token && 
      "query" in token &&
      "tokenType" in token
  );
}

export const isLPToken = (token : any) => {
    return isShellToken(token) || isExternalLPToken(token)
}

export function isDefaultShellToken(token: any) {
  return token.symbol == "shLP" && !isShellToken(token);
}

export const isShellV2Token = (token: any) => {
    return isExternalToken(token) && token.tokenType == 'Shell' && token.protocolVersion == 'V2'
}

export function maxTokenDecimals(token : string) {
    if(token.includes('WBTC') && !token.includes('+'))
        return 5
    else if(token.includes('ETH') && !token.includes('+'))
        return 3
    else
        return 2
}

export const tokenMap: { [id: string]: Token | ShellToken | NFTCollection | LBPToken } = {};

tokens.forEach((token) => {
  if (token.symbol !== "shLP") {
    tokenMap[token.symbol] = token;
  }
});

shellTokens.forEach((token) => {
  tokenMap[token.name] = token;
});

externalTokens.forEach((token) => {
    tokenMap[token.symbol] = token
})

externalLPTokens.forEach((token) => {
    tokenMap[token.symbol] = token;
    tokenColors[token.symbol] = generateColorHex(token.tokenType!)
});

nftCollections.forEach((collection) => tokenMap[collection.symbol] = collection)

lbpTokens.forEach((lbpToken) => {
    tokenMap[lbpToken.symbol] = lbpToken
    tokenMap[lbpToken.poolName] = {
        name: lbpToken.poolName,
        symbol: 'shLP',
        address: '0',
        wrapped: true,
        icon: lbpToken.poolIcon,
        oceanID: lbpToken.poolOceanID,
        pool: lbpToken.poolAddress,
        tokens: [lbpToken.symbol, lbpToken.pairToken],
        fee: 0,
        tokenType: 'Shell',
        protocolVersion: 'V2'
    }
})