const BASE_URL: string = "https://api.odos.xyz";
const CHAIN_ID: number = 42161;

const SOR_QUOTE_V2_ENDPOINT: string = `${BASE_URL}/sor/quote/v2`;
const INFO_LIQUIDITY_SOURCES_ENDPOINT: string = `${BASE_URL}/info/liquidity-sources/${CHAIN_ID}`;
const INFO_TOKENS_ENDPOINT: string = `${BASE_URL}/info/tokens/${CHAIN_ID}`;

const SOURCES: any = {
    // 'Shell': ["Shell V2"],
    'Curve': ["Curve Crypto Registry", "Curve Registry", "Curve Stable NG"],
    // 'WETH': ["Wrapped Ether"],
    // 'Balancer': ["Balancer V2 Stable"]
};

const QUOTE_DURATION = 60000;

export {
  BASE_URL,
  CHAIN_ID,
  SOR_QUOTE_V2_ENDPOINT,
  INFO_LIQUIDITY_SOURCES_ENDPOINT,
  INFO_TOKENS_ENDPOINT,
  SOURCES,
  QUOTE_DURATION,
};
