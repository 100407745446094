import React from "react";
import { BasicModalProps, Modal } from "../Modal/Modal";
import shellIcon from "../../assets/icons/shell.svg";
import warningIcon from "../../assets/icons/warning.svg";
import MetamaskIcon from "../../assets/icons/metamask.svg";
import WalletConnectIcon from "../../assets/icons/walletConnect.png";
import styled from "styled-components";
import { Spinner } from "../Loaders";
import { Media } from "../../styles";
import { InfoIcon } from "../Icons/InfoIcon";
import { ButtonPrimary, ButtonSecondary } from "../Buttons/Button";
import { Web3Button } from "@web3modal/react";

interface ChooseWalletModalProps extends BasicModalProps {
  onMetamaskClick: () => void;
  onWalletConnectClick: () => void;
}

export interface FailedModalProps extends BasicModalProps {
  onRecconectClick: () => void;
  onTryAnotherWalletClick: () => void;
}

export const ChooseWalletModal = ({
  isVisible,
  onClose,
  onMetamaskClick,
  onWalletConnectClick,
}: ChooseWalletModalProps) => {

    const web3ButtonRef = React.createRef<HTMLDivElement>();

    const handleWalletConnect = () => {
        web3ButtonRef.current
            ?.querySelector('w3m-core-button')?.shadowRoot
            ?.querySelector('w3m-connect-button')?.shadowRoot
            ?.querySelector('w3m-button-big')?.shadowRoot
            ?.querySelector('button')
            ?.click()

        onWalletConnectClick()
    };

    
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ShellImage src={shellIcon} alt="shell" />
      <Title>Connect your wallet</Title>
      <List>
        <Item data-testid="metamask-option" onClick={onMetamaskClick} icon={MetamaskIcon}>
          MetaMask
        </Item>
        {/* Testnet only needs metamask
        <Item onClick={onWalletConnectClick} icon={WalletConnectIcon}>
          WalletConnect
        </Item> */}
        <Item data-testid="walletconnect-option" onClick={handleWalletConnect} icon={WalletConnectIcon} >
          WalletConnect
          <div ref={web3ButtonRef} style={{display: 'none'}}>
            <Web3Button />
          </div>
        </Item>
      </List>
      <LearnAboutWallets>
        <TroubleshootText>Don’t have a wallet?</TroubleshootText>
        <ModalButton
          as="a"
          href="https://metamask.io/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get MetaMask
        </ModalButton>
      </LearnAboutWallets>
    </Modal>
  );
};

interface ItemProps {
    onClick: () => void;
    children: any;
    icon: string;
    'data-testid'?: string;
}
  
const Item = ({ 'data-testid': testId, onClick, children, icon }: ItemProps) => (
<ListItem>
    <ListButton data-testid={testId} onClick={onClick}>
    <ButtonIconWrapper>
        <ButtonIcon src={icon} alt="logo" />
    </ButtonIconWrapper>
    {children}
    </ListButton>
</ListItem>
);

export const ConnectionPendingModal = ({
  isVisible,
  onClose,
}: BasicModalProps) => {
  return (
    <PendingModal isVisible={isVisible} onClose={onClose}>
      <SpinContainer>
        <Spinner />
      </SpinContainer>
      <Title>Connecting your wallet</Title>
      <LoadingInfoContainer>
        <InfoIcon />
        Confirm the transaction on your wallet.
      </LoadingInfoContainer>
    </PendingModal>
  );
};

export const ConnectionFailedModal = ({
  isVisible,
  onClose,
  onRecconectClick,
  onTryAnotherWalletClick,
}: FailedModalProps) => {
  return (
    <FailedModal isVisible={isVisible} onClose={onClose}>
      <WarningIcon src={warningIcon} alt="warning"></WarningIcon>
      <Title>Connection failed</Title>
      <Subtitle>
        Try to reconnect to the wallet or change it to another one.
      </Subtitle>
      <ButtonsContainer>
        <ButtonPrimaryStyled onClick={onRecconectClick}>
          Reconnect the wallet
        </ButtonPrimaryStyled>
        <ButtonSecondaryStyled onClick={onTryAnotherWalletClick}>
          Try another wallet
        </ButtonSecondaryStyled>
      </ButtonsContainer>
    </FailedModal>
  );
};

const PendingModal = styled(Modal)`
    height: 331px;
`

const FailedModal = styled(Modal)`
    height: 462px;
`

const WarningIcon = styled.img`
  width: 64px;
  height: 64px;
  margin: 0 auto;
`;

const ButtonPrimaryStyled = styled(ButtonPrimary)`
  width: 100%;
  height: 62px;
`;

const ButtonSecondaryStyled = styled(ButtonSecondary)`
  width: 100%;
  height: 62px;
`;

const Subtitle = styled.div`
  text-align: center;
  margin-top: 12px;
  font-size: 16px;
  line-height: 1.36em;
  color: #7d7d97;
`;

const ButtonsContainer = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const ShellImage = styled.img`
  width: 100%;
  max-width: 50px;
  margin: 20px auto 0;
  object-fit: contain;

  ${Media.tablet} {
    margin: 64px auto 0;
  }
`;

// const Title = styled.h3`
//   font-weight: 500;
//   font-size: 30px;
//   line-height: 1.2em;
//   text-align: center;
//   letter-spacing: -0.03em;

//   ${Media.mobile} {
//     font-size: 24px;
//   }
// `;

const LoadingInfoContainer = styled.div`
  margin-top: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #00bdff;
  background: rgba(0, 189, 255, 0.05);
  border-radius: 8px;
`;

const Title = styled.h3`
  margin-top: 34px;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #ffffff;

  ${Media.tablet} {
    font-size: 24px;
    line-height: 30px;
  }
`;

const SpinContainer = styled.div`
  width: 63px;
  height: 63px;
  margin: 0 auto;
  img {
    width: 63px;
    height: 63px;
  }
`;

const List = styled.ul`
  margin-top: 34px;
`;

const ListItem = styled.li`
  & + & {
    margin-top: 10px;
  }
`;

const ListButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  height: 74px;
  padding: 20px;
  border: 1px solid #292941;
  border-radius: 16px;
  background: #1e2239;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const ButtonIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  margin-right: 20px;
`;

const ButtonIcon = styled.img`
  width: 100%;
  max-width: 100%;
`;

const LearnAboutWallets = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: auto;
`;

const TroubleshootText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
  text-align: center;
`;

const ModalButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: fit-content;
  min-width: 156px;
  margin: 16px auto 0;
  background: transparent;
  border: 1px solid #37dcf2;
  border-radius: 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
`;

// .Web3Button button {
//     display: none;
// }
