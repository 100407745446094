import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/logo.svg'
import logoMobile from '../../assets/logoMobile.svg'
import { useWidthBreakpoint } from '../../hooks'
import { breakpoints, Media } from '../../styles'
import { Network } from './Network'
import { Connection } from './Connection'
import { Notifications } from './Notifications/Notifications'
import { useAccount } from 'wagmi'

export const Header = () => {
  const landingPage = 'https://shellprotocol.io'
  const isTablet = useWidthBreakpoint(breakpoints.tablet)
  const { isConnected } = useAccount();

  return (
    <View>
      <Link href={landingPage} rel="noopener noreferrer">
        <Logo src={isTablet ? logoMobile : logo} alt="Logo" />
      </Link>
      <RightPart>
        {isConnected ? <Notifications /> : null}
        <Network />
        <Connection/>
      </RightPart>
    </View>
  )
}

const View = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 24px;

  ${Media.smallDesktop} {
    padding: 0;
    margin-bottom: 32px;
  }
`;

const RightPart = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const Link = styled.a`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 200px;
`

const Logo = styled.img`  
  width: 100%;
  max-width: 100%;
  object-fit: contain;

  ${Media.tablet} {
    max-width: 32px;
  }
`