import React, { useEffect, useState } from "react";
import { NFTCollection, NFT, tokenMap, NFT1155 } from "../../placeholders/tokens";
import { useAppSelector } from "../../store/hooks";
import { CollectionsList } from "./CollectionsList";
import { InsideCollection } from "./InsideCollection";
import { Inside1155 } from "./Inside1155";
import { build1155Displays } from "../../utils/nftHelpers";

interface CollectionsProps {
  collectionsList: NFTCollection[];
  onClose: () => void;
  selectedNFTs: (NFT | NFT1155)[];
  otherNFTs: {[collection : string] : (NFT | NFT1155)[]};
  updateSelectedNFTs: any;
  isInputToken: boolean;
  selectedCollection: NFTCollection | null;
  setSelectedCollection: (token: any) => void;
  isLoading: boolean;
  disabledTokens: string[]
}

export const Collections = ({
  collectionsList,
  onClose,
  isInputToken,
  selectedCollection,
  setSelectedCollection,
  isLoading,
  selectedNFTs,
  updateSelectedNFTs,
  otherNFTs,
  disabledTokens,
  ...props
}: CollectionsProps) => {

  const [wrappedToggle, setWrappedToggle] = useState(selectedCollection?.wrapped ?? false);
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);

  const [selected1155, setSelected1155] = useState<NFTCollection | undefined>(selectedCollection?.is1155 ? selectedCollection : undefined)
  const [items1155, setItems1155] = useState<NFT1155[]>([]);

  // Boolean flag for bottom token selection when doing an 1155 wrap/unwrap
  const bottomWrap1155 = !isInputToken && otherNFTs[selectedCollection?.wrapped ? selectedCollection?.symbol.substring(2) : 'sh' + selectedCollection?.symbol]?.length > 0

  const on1155Back = () => {
    setSelected1155(undefined)
    setItems1155([])
  }


  useEffect(() => {
    if(selected1155 && userNFTBalances[selected1155.symbol]){
        setItems1155(build1155Displays(selected1155, userNFTBalances[selected1155.symbol]))
    }
  }, [selected1155])

  return selected1155 && !bottomWrap1155 ? (
    <Inside1155
      selectedItem={selectedNFTs ? selectedNFTs[0] : null}
      items={items1155}
      title={selected1155.name}
      onClose={onClose}
      onBack={on1155Back}
      onSelect={(item: NFT1155) => {
        selected1155.id1155 = item.id
        setSelectedCollection(selected1155)
        updateSelectedNFTs(selected1155.symbol, [{
            id: item.id,
            symbol: selected1155.symbol,
            name: item.name,
            wrapped: selected1155.wrapped,
            rarity: 0,
            image: item.image,
            balance: item.balance,
            desc: item.desc
        }])
      }}
    />
    ) : (
    <CollectionsList
      collectionsList={collectionsList}
      selectedCollection={selectedCollection}
      onClose={onClose}
      onSelect={(collection : NFTCollection) => {
        if(collection.is1155){
            const pairID = collection.wrapped ? collection.symbol.substring(2) : 'sh' + collection.symbol
            if(otherNFTs[pairID]?.length == 1){
               
                const pairItem : any = { ...otherNFTs[pairID][0] };
                const pairBalance = userNFTBalances[collection.symbol].filter((nft) => nft.id == pairItem.id).map((nft) => nft.balance)
                const wrapPrefix = 'Wrapped '
                pairItem.name = collection.wrapped ? wrapPrefix + pairItem.name : pairItem.name.replace(wrapPrefix, "")
                pairItem.symbol = collection.symbol
                pairItem.balance = pairBalance.length > 0 ? pairBalance[0] : 0

                collection.id1155 = pairItem.id
                setSelectedCollection(collection)

                updateSelectedNFTs(collection.symbol, [pairItem])
            } else {
                setSelected1155(collection)
            }
        } else {
            setSelectedCollection(collection)
        }
      }}
      title="Select NFT Collection"
      wrappedToggle={wrappedToggle}
      setWrappedToggle={setWrappedToggle}
      isLoading={isLoading}
      disabledTokens={disabledTokens}
      {...props}
    />
  )
};
