import { CONNECTED_CHAIN_ID } from "./chains";

export const SHELL_API_URL = "https://staging-points-api.shellprotocol.io/";

export const POINTS_API = SHELL_API_URL + 'points/' + CONNECTED_CHAIN_ID + '/';
export const POOL_API = SHELL_API_URL + 'analytics/' + CONNECTED_CHAIN_ID + '/';
export const COLLAB_DROP_API = SHELL_API_URL + 'points/collab-drop/';
export const CRAB_DROP_API = SHELL_API_URL + 'points/crabs/';
export const LBP_API = SHELL_API_URL + 'analytics/' + CONNECTED_CHAIN_ID + '/lbp/';
export const HISTORY_API = SHELL_API_URL + 'shellscan/' + CONNECTED_CHAIN_ID + '/';
export const PRICES_API = SHELL_API_URL + 'points/prices/current';
export const NFT_PRICES_API = SHELL_API_URL + 'points/' + CONNECTED_CHAIN_ID + '/nftprices';
export const EXPLORER_API = SHELL_API_URL + 'points/' + CONNECTED_CHAIN_ID + '/explorer'
