import { formatUnits, parseEther } from "@ethersproject/units";
import { Zero } from "@ethersproject/constants"
import { BigNumber, Contract } from "ethers";
import { ExternalLPToken, externalLPTokens, externalTokens, isExternalLPToken, isLPToken, isNFTCollection, isShellToken, isShellV2Token, tokenMap } from "../placeholders/tokens";
import { Edge, getTokenID } from "./LiquidityGraph";
import * as ShellV2 from './ocean/index';
import { LiquidityPoolABI } from "../constants/ABI/LiquidityPoolABI";
import { OCEAN_ADDRESS, OLD_OCEAN_ADDRESS } from "../constants/addresses";
import { OceanABI } from "../constants/ABI/OceanABI";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { addPrice } from "../store/pricesSlice";
import { OceanPoolQueryABI } from "../constants/ABI/OceanPoolQueryABI";
import { ContractCallContext, ContractCallResults, Multicall } from "ethereum-multicall";
import { multicall } from "./nftHelpers";
import { Curve2PoolABI } from "@/constants/ABI/Curve2PoolABI";
import { ERC20ABI } from "@/constants/ABI/ERC20ABI";
import * as constants from "@/utils/sor/constants";
import * as types from "@/utils/sor/types";

export interface PoolState {
    xBalance: BigNumber
    yBalance: BigNumber
    totalSupply: BigNumber
    impAddress: string
}

export class PoolQuery {

    poolMap : {[name: string] : Contract} = {}
    ocean : Contract
    oceanPoolQuery : Contract
    oldOceanPoolQuery : Contract

    balancerVaultAddress = "0xBA12222222228d8Ba445958a75a0704d566BF2C8";

    prices = useAppSelector(state => state.prices.prices)
    dispatch = useAppDispatch()

    static tokenMap: Record<string, types.TokenInfo> = {};
    static async initializeSorTokenMap() {
        if (Object.keys(PoolQuery.tokenMap).length === 0) {
            try {
                const response = await fetch(constants.INFO_TOKENS_ENDPOINT, {
                    method: "GET",
                    headers: { "Content-Type": "application/json" },
                });
                if (!response.ok) {
                    throw new Error(`Network response was not ok: ${response.status} ${response.statusText}`);
                }
                const tokenData = await response.json();
                PoolQuery.tokenMap = tokenData.tokenMap;
            } catch (error) {
                console.error("Error fetching token map:", error);
            }
        }
    }

    constructor(connectedWallet : any) {
        PoolQuery.initializeSorTokenMap();
        const lpTokens : any[] = Object.values(tokenMap).filter((token) => isLPToken(token))
        lpTokens.forEach((lpToken) => {
            this.poolMap[lpToken.name] = new Contract(
                isShellV2Token(lpToken) ? lpToken.address : lpToken.pool,
                LiquidityPoolABI,
                connectedWallet
            );
        })

        this.ocean = new Contract(
            OCEAN_ADDRESS,
            OceanABI,
            connectedWallet
        );

        this.oceanPoolQuery = new Contract(
            '0xEaE5B59499a461887fBf2BF47887e4e4cB91D703',
            OceanPoolQueryABI, 
            connectedWallet
        )

        this.oldOceanPoolQuery = this.oceanPoolQuery
    }

    getPools = async (paths: Edge[][]) => {

        const pathPools = paths.map((path) => path.map((edge) => edge.pool ?? '').filter((pool) => pool !== '' && isLPToken(tokenMap[pool])))
        const poolStates : { [id: string]: PoolState } = {}
        const sharedPools: string[] =[]

        const oldOceanMulticallContext: ContractCallContext[] = [
            {
                reference: 'Ocean v2',
                contractAddress: this.oldOceanPoolQuery.address,
                abi: OceanPoolQueryABI as any,
                calls: []
            }
        ];

        const oceanMulticallContext: ContractCallContext[] = [
            {
                reference: 'Ocean v3',
                contractAddress: this.oceanPoolQuery.address,
                abi: OceanPoolQueryABI as any,
                calls: []
            }
        ];

        const promises: {[tokenID: string]: Promise<any>} = {}
    
        for(let i = 0; i < pathPools.length; i++){
    
            for(let j = 0; j < pathPools[i].length; j++){
                
                let pool = pathPools[i][j]

                const token = tokenMap[pool]

                if(isExternalLPToken(token)){

                    if(token.tokenType == 'Shell'){

                        oldOceanMulticallContext[0].calls.push({
                            reference: token.symbol,
                            methodName: 'getPoolState',
                            methodParameters: [token.address]
                        })
                    } else if(token.tokenType == null){ 
                        poolStates[token.symbol] = {
                            xBalance: BigNumber.from(0), 
                            yBalance: BigNumber.from(0), 
                            totalSupply: BigNumber.from(0), 
                            impAddress: token.query
                        }  
                    } else {

                        let contractCallContext: ContractCallContext[] = [];

                        if (token.tokenType == "Curve") {
                           contractCallContext = [
                              {
                                  reference: token.symbol,
                                  contractAddress: token.address,
                                  abi: Curve2PoolABI as any,
                                  calls: [
                                  {
                                      reference: "xBalance",
                                      methodName: "balances",
                                      methodParameters: [0]
                                  },
                                  {
                                      reference: "yBalance",
                                      methodName: "balances",
                                      methodParameters: [1]
                                  },
                                  {
                                      reference: "totalSupply",
                                      methodName: "totalSupply",
                                      methodParameters: []
                                  },
                                  {
                                      reference: "decimals",
                                      methodName: "decimals",
                                      methodParameters: []
                                  },
                                  ]
                              }
                          ];

                        }
                        token.tokens.forEach((token) => {
                            const tokenData = tokenMap[token]
                            contractCallContext.push({
                                reference: token,
                                contractAddress: tokenData.address,
                                abi: ERC20ABI as any,
                                calls: [{
                                    reference: "decimals",
                                    methodName: "decimals",
                                    methodParameters: []
                                }]
                            })
                        })
                        promises[token.symbol] = multicall.call(contractCallContext)
                    }
                } else {
                    oceanMulticallContext[0].calls.push({
                        reference: token.name,
                        methodName: 'getPoolState',
                        // @ts-ignore
                        methodParameters: [token.pool]
                    })
                }
    
                for(let k = i + 1; k < pathPools.length; k++){
                    if(pathPools[k].includes(pool) && !(pool in sharedPools) && pool !== 'ETH+WETH'){
                        sharedPools.push(pool)
                    }
                }
            }
        }
        if(oldOceanMulticallContext[0].calls.length > 0)
            promises['Ocean v2'] = multicall.call(oldOceanMulticallContext)

        if(oceanMulticallContext[0].calls.length > 0)
            promises['Ocean v3'] = multicall.call(oceanMulticallContext)

        const responses = await Promise.all(Object.values(promises))

        Object.keys(promises).forEach((tokenID, index) => {
            const tokenResponse = responses[index]

            let state: any[] = []
            let token: any

            if(tokenID == 'Ocean v2' || tokenID == 'Ocean v3'){
                const results: ContractCallResults = tokenResponse
                results.results[tokenID].callsReturnContext.forEach((returnContext) => {
                    state = returnContext.returnValues
                    token = tokenMap[returnContext.reference]
                    poolStates[returnContext.reference] = {
                        xBalance: state[0], 
                        yBalance: state[1], 
                        totalSupply: state[2], 
                        impAddress: tokenID == 'Ocean v2' ? token.query : state[3]
                    }  
                })
            } else {

                token = tokenMap[tokenID]

                const results: ContractCallResults = tokenResponse  

                if(token.tokenType == 'Curve'){
                    results.results[token.symbol].callsReturnContext.forEach((returnContext) => {
                        if(returnContext.reference == 'xBalance'){
                            const xDecimals = results.results[token.tokens[0]].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(returnContext.returnValues[0]), xDecimals)))
                        } else if(returnContext.reference == 'yBalance'){
                            const yDecimals = results.results[token.tokens[1]].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(returnContext.returnValues[0]), yDecimals)))
                        } else if(returnContext.reference == 'totalSupply'){
                            const totalSupplyDecimals = results.results[token.symbol].callsReturnContext.at(-1)!.returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(returnContext.returnValues[0]), totalSupplyDecimals)))
                        }
                    })    
                } else {
                    const addresses = results.results[token.symbol].callsReturnContext[0].returnValues[0]
                    const balances = results.results[token.symbol].callsReturnContext[0].returnValues[1]
                    token.tokens.forEach((token: any, index: number) => {
                        const tokenAddress = tokenMap[token].address
                        const balanceIndex = addresses.indexOf(tokenAddress)
                        if(index === 0){
                            const xDecimals = results.results[token].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(balances[balanceIndex]), xDecimals)))
                        }
                        else {
                            const yDecimals = results.results[token].callsReturnContext[0].returnValues[0]
                            state.push(parseEther(formatUnits(BigNumber.from(balances[balanceIndex]), yDecimals)))
                        }
                    })

                    state.push(BigNumber.from(results.results['totalSupply'].callsReturnContext[0].returnValues[0]))
                }
                
                poolStates[tokenID] = {
                    xBalance: state[0], 
                    yBalance: state[1], 
                    totalSupply: state[2], 
                    impAddress: token.query
                }  
            } 
        })

        return {states: poolStates, sharedPools: sharedPools}
    
    }

    sortPaths = (paths: Edge[][], amounts: BigNumber[]) => {

        // Create an array of indices for the paths array
        const indices = paths.map((_, index) => index);

        // Sort the indices array based on the length of subarrays in paths
        indices.sort((a, b) => paths[b].length - paths[a].length);

        // Create sorted arrays for paths and nftPaths based on the sorted indices
        const sortedPaths = indices.map(index => paths[index]);
        const sortedAmounts = indices.map(index => amounts[index]);

        return [sortedPaths, sortedAmounts]
    }

    filterInputNFTPath = (paths: Edge[][]) => {
        const inputNFTPaths: Edge[][] = []
        paths.forEach((path) => {
            const inputNFTPath : Edge[] = []

            while(path.length > 0 && isNFTCollection(path[0].token)){
                inputNFTPath.push(path.shift()!)
            }
            if(path.length) inputNFTPath.push(path[0])
            inputNFTPaths.push(inputNFTPath)
        })

        return [paths, inputNFTPaths]

    }

    filterOutputNFTPath = (paths: Edge[][]) => {
        const outputNFTPaths: Edge[][] = []
        paths.forEach((path) => {
            const outputNFTPath : Edge[] = []

            while(path.length > 0 && isNFTCollection(path[path.length - 1].token)){
                outputNFTPath.unshift(path.pop()!)
            }
            if(path.length) outputNFTPath.unshift(path[path.length - 1])
            outputNFTPaths.push(outputNFTPath)
        })
        return [paths, outputNFTPaths]

    }

    adjustNFTAmount = (amount : number, nftPath : Edge[]) => {
        const exchangeRate = 100
        nftPath.forEach((step : Edge) => {
            if(step.action == 'Fractionalize' || step.action == 'Unfractionalize') amount *= exchangeRate
        })
        return amount
    }

    getPoolQueryContract = (lpToken : any) => {
        if(isExternalLPToken(lpToken)){
            return lpToken.tokenType == 'Shell' ? lpToken.address : lpToken.query
        } else {
            return lpToken.pool
        }
    }

    getOceanID = (token: any) => {
        if(isShellV2Token(token)){
            const externalToken = externalTokens.concat(externalLPTokens).find((extToken) => !extToken.wrapped &&  token.symbol == 'sh' + extToken.symbol)!
            return externalToken.oceanID!
        } else {
            return token.oceanID!
        }
    }

    query = async (path: Edge[], amount: BigNumber, pools: any, specifiedInput : boolean) => {

        // @ts-ignore
        const sharedPools = pools.sharedPools.map((pool) => isShellToken(tokenMap[pool]) ? tokenMap[pool].pool : tokenMap[pool].query)
        const pathPools = []

        const steps = []

        if(specifiedInput){
            for (let j = 0; j < path.length - 1; j++) {
                const action = path[j+1].action
                if(action == 'Wrap' || action == 'Unwrap') continue
                steps.push({
                    token: this.getOceanID(path[action == 'Withdraw' ? j + 1 : j].token),
                    // @ts-ignore
                    pool: this.getPoolQueryContract(tokenMap[path[j+1].pool]),
                    action: action == 'Swap' ? 0 : action == 'Deposit' ? 2 : 4
                })
                pathPools.push(path[j+1].pool)
            }
        } else {
            for (let j = path.length - 1; j >= 1; j--) {
                const action = path[j].action
                if(action == 'Wrap' || action == 'Unwrap') continue
                steps.push({
                    token: this.getOceanID(path[action == 'Deposit' ? j - 1 : j].token),
                    // @ts-ignore
                    pool: this.getPoolQueryContract(tokenMap[path[j].pool]),
                    action: action == 'Swap' ? 1 : action == 'Deposit' ? 3 : 5
                })
                pathPools.push(path[j].pool)
            }
        }

        const poolStates = pathPools.map((pool: string) => pools.states[pool])
        if(!specifiedInput) poolStates.reverse()

        const result = await this.oceanPoolQuery.query(steps, amount, sharedPools, poolStates)
        
        const newPoolStates: {[id: string]: any} = {}

        pathPools.forEach((pool, index) => {
            newPoolStates[pool] = result[1][index]
        })

        return {
            amount: result[0],
            poolStates: newPoolStates
        }
    }

    getUSDPrice = async (token : any, cachedPrices? : any) => {
        const prices = cachedPrices ?? this.prices

        const tokenID = getTokenID(token)
        if(prices[tokenID]){
            if(typeof prices[tokenID] == 'number'){
                return prices[tokenID]
            } else {
                return prices[tokenID].find((item: any) => item.id == token.id1155)?.price ?? 0
            }
        } else if(isShellToken(token)){
            const price = await this.getShellTokenPrice(token, prices)
            this.dispatch(addPrice({name: tokenID, price: price}))
            if(cachedPrices) cachedPrices[tokenID] = price
            return price
        } else if(isExternalLPToken(token)){
            const price = await this.getExternalLPTokenPrice(token, prices)
            this.dispatch(addPrice({name: tokenID, price: price}))
            if(cachedPrices) cachedPrices[tokenID] = price
            return price
        } else {
            return 0
        }
    }

    getShellTokenPrice = async (shellToken : any, prices : any) => {
    
        const poolContract = this.poolMap[shellToken.name]      
        const childTokens = shellToken.tokens.map((child : any) => tokenMap[child])
        const priceBalances = []
    
        const childOceanIDs = childTokens.map((childToken : any) => 
            isShellToken(childToken) ? childToken.oceanID : 
            childToken.wrapped || isShellV2Token(childToken) ? 
            childToken.oceanID : 
            ShellV2.utils.calculateWrappedTokenId(childToken.address, 0)
        )

        const contractCallContext: ContractCallContext[] = [
            {
                reference: 'Ocean',
                contractAddress: isShellV2Token(shellToken) ? OLD_OCEAN_ADDRESS : OCEAN_ADDRESS,
                abi: OceanABI as any,
                calls: [{
                    reference: "balanceOfBatch",
                    methodName: "balanceOfBatch",
                    methodParameters: [[poolContract.address, poolContract.address], childOceanIDs]
                }]
            },
            {
                reference: 'Pool',
                contractAddress: poolContract.address,
                abi: LiquidityPoolABI as any,
                calls: [{
                    reference: "getTokenSupply",
                    methodName: "getTokenSupply",
                    methodParameters: [shellToken.oceanID]
                }]
            },
        ];

        const results: ContractCallResults = await multicall.call(contractCallContext)

        const balances = results.results['Ocean'].callsReturnContext[0].returnValues.map((value) => BigNumber.from(value))

        for(let i = 0; i < childTokens.length; i++){
            const childToken = childTokens[i]
            const childTokenID = getTokenID(childToken)
            const price = prices[childTokenID] ?? (isShellToken(childToken) ? await this.getShellTokenPrice(childToken, prices) : 0)
            priceBalances.push({
                token: childTokenID,
                price: parseEther(price.toFixed(18)),
                balance: balances[i]
            })
        }

        let totalValue = Zero
        priceBalances.forEach((data : any) => totalValue = totalValue.add(data.balance.mul(data.price)))
        const totalSupply = results.results['Pool'].callsReturnContext[0].returnValues[0]
        const price : BigNumber = totalValue.div(totalSupply)
        
        return parseFloat(formatUnits(price))
    }
    
    getExternalLPTokenPrice = async (lpToken: any, prices: any) => {

        const childTokens = lpToken.tokens.map((child : any) => tokenMap[child])
        const priceBalances = []

        let contractCallContext: ContractCallContext[] = []

        let results: ContractCallResults

        let balances: BigNumber[] = []

        if(!lpToken.tokenType) return 0

        contractCallContext = [
            {
                reference: lpToken.name,
                contractAddress: lpToken.address,
                abi: Curve2PoolABI as any,
                calls: [{
                    reference: "xBalance",
                    methodName: "balances",
                    methodParameters: [0]
                },
                {
                    reference: "yBalance",
                    methodName: "balances",
                    methodParameters: [1]
                },
                {
                    reference: "totalSupply",
                    methodName: "totalSupply",
                    methodParameters: []
                }]
            }
        ];

        childTokens.forEach((childToken: any) => {
            contractCallContext.push({
                reference: childToken.symbol,
                contractAddress: childToken.address,
                abi: ERC20ABI as any,
                calls: [{
                    reference: "decimals",
                    methodName: "decimals",
                    methodParameters: []
                }]
            })
        })

        results = await multicall.call(contractCallContext)
        balances = results.results[lpToken.name].callsReturnContext.map((returnContext) => BigNumber.from(returnContext.returnValues[0]))


        for(let i = 0; i < childTokens.length; i++){
            const childToken = childTokens[i]
            const childTokenID = getTokenID(childToken)
            const price = prices[childTokenID]
            priceBalances.push({
                token: childTokenID,
                price: parseEther(price.toFixed(18)),
                balance: parseEther(formatUnits(balances[i], results.results[childTokenID].callsReturnContext[0].returnValues[0]))
            })
        }

        let totalValue = Zero
        priceBalances.forEach((data : any) => totalValue = totalValue.add(data.balance.mul(data.price)))
        const totalSupply = balances[balances.length - 1]
        const price : BigNumber = totalValue.div(totalSupply)
        
        return parseFloat(formatUnits(price))

    }
}