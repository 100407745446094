import * as React from "react";
import styled, { keyframes } from "styled-components";

interface SkeletonBoxProps {
  isLoading: boolean;
  width?: string;
  height?: string;
  borderRadius?: string;
  backgroundColor?: string;
  shimmerColor?: string;
  animationDuration?: string;
  padding?: string;
  style?: React.CSSProperties;
}

interface StyledSkeletonProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  backgroundColor?: string;
  shimmerColor?: string;
  animationDuration?: string;
  padding?: string;
}

export const SkeletonBox: React.FC<SkeletonBoxProps> = ({
  isLoading,
  width = "100%",
  height = "100%",
  borderRadius = "0",
  backgroundColor = "rgb(23, 27, 51)",
  shimmerColor = "rgb(33, 37, 61)",
  animationDuration = "1.2s",
  padding = "0",
  style = {},
}) => {
  if (!isLoading) {
    return null;
  }

  return (
    <StyledSkeleton
      width={width}
      height={height}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      shimmerColor={shimmerColor}
      animationDuration={animationDuration}
      padding={padding}
      style={style}
    >
      <div>#440</div>
    </StyledSkeleton>
  );
};

const shimmer = keyframes`
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
`;

const StyledSkeleton = styled.div<StyledSkeletonProps>`
  display: flex;
  width: ${({ width }) => width};
  height: ${({ height }) => height};

  & > div {
    font-size: 16px;
    line-height: 1em;
    width: 100%;
    height: 100%;
    background-color: ${({ backgroundColor }) => backgroundColor};
    border-radius: ${({ borderRadius }) => borderRadius};
    padding: ${({ padding }) => padding};
    background: linear-gradient(
      to right,
      ${({ backgroundColor }) => backgroundColor} 8%,
      ${({ shimmerColor }) => shimmerColor} 25%,
      ${({ backgroundColor }) => backgroundColor} 80%
    );
    background-size: 800px 100px;
    animation: ${shimmer} ${({ animationDuration }) => animationDuration} infinite;
    color: transparent;
    overflow: hidden;
  }
`;
