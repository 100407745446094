import React from 'react'
import styled, { css } from 'styled-components'

export const MultiplierCell = ({ value }: any) => <StyledSpan glow={value > 1}>{value}x</StyledSpan>

const StyledSpan = styled.span<{glow: boolean}>`
  ${({ glow }) => glow && css`
    -webkit-animation-name: glow;
    -moz-animation-name: glow;
    animation-name: glow;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-iteration-count: infinite;

    @keyframes glow {
        0% {
            text-shadow: 0 0 6px #00BDFF;
        }
        
        50% {
            text-shadow: 0 0 14px #00bdff;
        }

        100% {
            text-shadow: 0 0 6px #00bdff;
        }
    }
  `};
`