export const OCEAN_ADDRESS = "0xe5Eb94CEaDEB1A87656b7FB57Cf22D01c1B3229d";
export const OLD_OCEAN_ADDRESS = OCEAN_ADDRESS;
export const OCEAN_OWNER_ADDRESS = "0x67De6393B57e21B48cBA9eB0EbBCDcF3ecA22775";
export const OCEAN_POOL_QUERY_ADDRESS = "0x7c73dCE4548692fb117092ABe8294477C7f11124";

export const TOUCAN_ADDRESS = "";

export const WRAPPED_ETHER_ID = "0x97a93559a75ccc959fc63520f07017eed6d512f74e4214a7680ec0eefb5db5b4";
export const ETH_ADDRESS = "0x0000000000000000000000000000004574686572";

export const OLD_BOOTY_ADDRESS = ""
export const BOOTY_ADDRESS = ""

export const CRAB_ADDRESS = ''

export const SHELL_ADDRESS = ''

export const VESTING_ADDRESS = '';
export const STREAM_ADDRESS = '';
export const STAKING_ADDRESS = ''


