import React from "react";
import styled from "styled-components";
import { Media } from "../../styles";

interface ItemProps {
  dataTestId?: string;
  icon: string;
  iconRight?: string;
  title?: string;
  subtitle?: string;
  aboveTitle?: string;
  selected?: boolean
  onClick: () => void;
}

export const CollectionButton = ({
  dataTestId,
  icon,
  iconRight,
  title,
  subtitle,
  aboveTitle,
  selected,
  onClick,
}: ItemProps) => {
  return (
    <Button data-testid={dataTestId} selected={selected} onClick={onClick}>
      <Logo src={icon} alt="Logo" />
      <Content>
        <AboveTitle>{aboveTitle}</AboveTitle>
        {title && <Title>{title}</Title>}
        <SubTitle>{subtitle}</SubTitle>
      </Content>
      {iconRight && (
        <IconRight>
          <img src={iconRight} alt="" />
        </IconRight>
      )}
    </Button>
  );
};

const Button = styled.button<{selected: boolean | undefined}>`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 13px 24px 13px 20px;
  text-align: left;
  background: ${props => props.selected ? 'linear-gradient(#171B33, #171B33) padding-box, linear-gradient(to right, #37dcf2, #07c0fb) border-box' : '#171B33'};
  border: 1px solid transparent;
  border-radius: 16px;

  &:hover,
  &:focus {
    box-shadow: 0px 19px 45px #04081C;
    border-color: transparent;
    outline: ${props => props.selected ? 'none' : '2px solid #2C5173'};
  }
  ${Media.mobile} {
    padding-block: 8px;
  }
`;

const Logo = styled.img`
  width: 60px;
  height: 60px;
  margin-right: 12px;
  border-radius: 50%;
  overflow: hidden;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px 0;
`;

const Title = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;

const AboveTitle = styled.p`
  font-size: 14px;
  line-height: 17px;
  color: #7d7d97;
`;

const SubTitle = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;

const IconRight = styled.div`
  margin-left: auto;
`;
