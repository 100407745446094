import { createSlice } from '@reduxjs/toolkit'
interface State {
    prices: Record<string, any>
}

const initialState : State = {prices: {}}

const pricesSlice = createSlice({
  name: 'prices',
  initialState,
  reducers: {
      addPrice: (state, action) => {
          let newPrices = {...state.prices}
          newPrices[action.payload.name] = action.payload.price
          return {
              ...state,
              prices: newPrices
          }
      },
      clearPrices: () => {
          return initialState
      }
  }
})

export const { addPrice, clearPrices } = pricesSlice.actions

export default pricesSlice.reducer