import React from "react";
import styled from "styled-components";
import { CloseButton } from "../Modal/Modal";
import { List, ListItem, Title, TokensModalHeader } from "../TokensModal/TokensModal";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import cross from "../../assets/icons/cross.svg";
import { NFT1155, lbpTokens, tokenMap } from "../../placeholders/tokens";
import { TokenButton } from "../List/TokenButton";
import { extract1155Data } from "../../utils/nftHelpers";

interface Inside1155Props {
  selectedItem: any;
  items: NFT1155[];
  title: string;
  onClose: () => void;
  onBack: () => void;
  onSelect: any;
}

export const Inside1155 = ({
  selectedItem,
  items,
  title,
  onClose,
  onBack,
  onSelect,
}: Inside1155Props) => {

  const checkForFractional = (item : any) => {
    for(let tokenID of Object.keys(tokenMap)){
        const data1155 = extract1155Data(tokenID)
        if(data1155){
            if(data1155.symbol == item.symbol && parseInt(data1155.id) == item.id){
                return true
            }
        }
    }
    return false
  }

  const getStatus = (itemName : string) => {
    const lbpToken = lbpTokens.filter(lbpToken => lbpToken.name == itemName)[0]
    if(lbpToken){
        return lbpToken.status
    } else {
        return ''
    }
  }

  return (
    <>
      <TokensModalHeader>
        <Button onClick={onBack}>
          <img src={arrowLeft} alt="Back" />
        </Button>
        <Title>{title}</Title>
        <CloseButton onClick={onClose}>
          <img src={cross} alt="Close" />
        </CloseButton>
      </TokensModalHeader>
      <List>
        {items.filter((a: any) => checkForFractional(a)).map((item, index) => (
          <ListItem key={index}>
            <TokenButton
              dataTestId={`token-btn-${item.id}`}
              icon={item.image}
              title={item.name}
              subtitle={''}
              onClick={() => onSelect(item)}
              textRight={item.balance.toString()}
              isLoading={false}
              selected={item.name == selectedItem?.name}
              nftType='1155'
              disabled={!checkForFractional(item)}
              status={getStatus(item.name)}
              />
          </ListItem>
        ))}
      </List>
    </>
  );
};

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
`;
