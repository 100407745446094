import React, { ReactNode } from "react";
import { WagmiConfig, createClient, configureChains, createStorage, Chain } from "wagmi";
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'
import { publicProvider } from 'wagmi/providers/public'
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import arbLogo from '../assets/icons/arbitrum-logo.svg'

export const projectId = "1a0918d9c1353afb69eeedbbbcb3eb0a"; // walletconnect cloud
export const infuraId = "6a2e4095949b446d9b2d93a50182519a";
export const alchemyId = "vqPEBfzKazq958r46AU6cUf4Jxldxs9L";

export const blastSepolia = {
  id: 168587773,
  name: 'Blast Sepolia',
  network: 'blast-sepolia',
  nativeCurrency: {
    decimals: 18,
    name: 'Ether',
    symbol: 'ETH',
  },
  rpcUrls: {
    public: { http: ["https://sepolia.blast.io"] },
    default: { http: ["https://sepolia.blast.io"] },
  },
  blockExplorers: {
    etherscan: { name: 'Blastscan', url: 'https://testnet.blastscan.io' },
    default: { name: 'Blastscan', url: 'https://testnet.blastscan.io' },
  },
  contracts: {
    multicall3: {
      address: "0xcA11bde05977b3631167028862bE2a173976CA11",
      blockCreated: 756690
    }
  },
} as const satisfies Chain

const chains = [blastSepolia];

function createLimitedStorage(storage: Storage, maxSize: number): Storage {
  return {
      get length() {
          return storage.length;
      },
      getItem: (key) => {
          return storage.getItem(key);
      },
      setItem: (key, value) => {
          if (value && value.length > maxSize) {
              throw new Error(`Storage limit exceeded! Max allowed size is ${maxSize} characters.`);
          }
          storage.setItem(key, value);
      },
      removeItem: (key) => {
          storage.removeItem(key);
      },
      clear: () => {
          storage.clear();
      },
      key: (index) => {
          return storage.key(index);
      }
  };
}

const limitedLocalStorage = createLimitedStorage(window.localStorage, 5000);
const wagmiStorage = createStorage({ storage: limitedLocalStorage });

const { provider } = configureChains(chains, [alchemyProvider({ apiKey: alchemyId }), w3mProvider({ projectId }), publicProvider()]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 2, chains }),
  provider,
  storage: wagmiStorage
});

const ethereumClient = new EthereumClient(wagmiClient, chains);
interface Props {
  children: ReactNode;
}

export function WagmiProvider(props: Props) {
  return (
    <>
      <WagmiConfig client={wagmiClient}>{props.children}</WagmiConfig>
      <Web3Modal
        defaultChain={blastSepolia}
        enableAccountView={true}
        chainImages={
          {
            168587773: 'https://cdn.routescan.io/_next/image?url=https://cms-cdn.avascan.com/cms2/blast.dead36673539.png&w=32&q=100', // Blast Sepolia
          }
        }
        themeVariables={{
          "--w3m-accent-color": "#07c0fb",
          "--w3m-accent-fill-color": "#0a0e27",
          "--w3m-button-border-radius": "16px",
          "--w3m-text-medium-regular-size": "16px",
          "--w3m-text-medium-regular-weight": "600",
          "--w3m-text-medium-regular-line-height": "19px",
        }}
        projectId={projectId}
        ethereumClient={ethereumClient}
      />
    </>
  );
}