import React, { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect, useEnsName } from "wagmi";
import styled from "styled-components";
import { reduceString } from "../../utils/reduceString";
import power from "../../assets/icons/power.svg";
import { useAppDispatch } from "../../store/hooks";
import { clearBalances } from "../../store/balancesSlice";
import { clearPoints } from "@/store/pointsSlice";
import { ChooseWalletModal, ConnectionFailedModal } from "./ConnectionModals";
import { Media } from "../../styles";
import { useLocation } from "react-router-dom";

enum ConnectingModals {
  chooseWallet,
  connectionPending,
  connectionFailed,
}

export const Connection = () => {
  const [modal, setModal] = useState<ConnectingModals | null>(null);
  const location = useLocation();
  const appUrl = "shell-v2-internal-testnet.netlify.app";

  const closeModal = () => setModal(null);

  const { connect, connectors } = useConnect();
  const { address: walletAddress, isConnected } = useAccount();
  const { data: ensName } = useEnsName({ address: walletAddress });
  const { disconnect } = useDisconnect();

  const metamaskConnector = connectors.find(
    (conn) => conn.name === "MetaMask"
  )!;

  const dispatch = useAppDispatch();

  const handleMetamaskConnect = async () => {
    setModal(ConnectingModals.connectionPending);
    if (window.ethereum?.isMetaMask) {
        try {
          connect({ connector: metamaskConnector });
        } catch {
            setTroubleshootVisible(true)
        }
    } else {
      window.location.href =
        "https://metamask.app.link/" + appUrl + location.pathname;
    }
    closeModal();
  };

  const handleMetamaskDisconnect = () => {
    disconnect();
  };

  const [connectedWallet, setConnectedWallet] = useState(walletAddress);

  const [troubleshootVisible, setTroubleshootVisible] = useState(false);

//   useEffect(() => {
//     let timer: NodeJS.Timeout;
//     if (isConnecting) {
//       timer = setTimeout(() => setTroubleshootVisible(true), 5000);
//     }
//     return () => clearTimeout(timer);
//   }, [isConnecting]);

  useEffect(() => {
    if (walletAddress !== connectedWallet) {
      dispatch(clearBalances());
      dispatch(clearPoints());
      setConnectedWallet(walletAddress);
    }
  }, [walletAddress]);

  if (troubleshootVisible) {
    return (
      <ConnectionFailedModal
        isVisible={true}
        onClose={closeModal}
        onRecconectClick={() => handleMetamaskConnect()}
        onTryAnotherWalletClick={() => {
            setTroubleshootVisible(false)
            setModal(ConnectingModals.chooseWallet)
        }}
      />
    );
  }

//   if (isConnecting && modal != null) {
//     return <ConnectionPendingModal isVisible={true} onClose={closeModal} />;
//   }

  if (!isConnected) {
    return (
      <>
        <ConnectButton data-testid="connect-wallet-btn" onClick={() => setModal(ConnectingModals.chooseWallet)} >
          Connect Wallet
        </ConnectButton>
        <ChooseWalletModal
          data-testid="choose-wallet-modal"
          isVisible={modal === ConnectingModals.chooseWallet}
          onClose={closeModal}
          onMetamaskClick={() => handleMetamaskConnect()}
          onWalletConnectClick={() => setModal(ConnectingModals.connectionPending)}
        />
      </>
    );
  } else {
    let displayValue;

    if (walletAddress !== undefined) {
      displayValue = ensName ? `${ensName}` : reduceString(walletAddress, 6, 4);
    }

    return (
      <DisconnectButton data-testid="disconnect-btn" onClick={() => handleMetamaskDisconnect()}>
        {displayValue}
        <PowerImage src={power} alt="Disconnect" />
      </DisconnectButton>
    );
  }
};

const Button = styled.button`
  height: 60px;

  ${Media.tablet} {
    height: 50px;
  }
`;

const ConnectButton = styled(Button)`
  padding: 18px 28px;
  background: linear-gradient(90.44deg, #37dcf2 0.87%, #07c0fb 100%);
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0a0e27;

  ${Media.tablet} {
    padding: 8px 20px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const DisconnectButton = styled(Button)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 18px 28px;
  background: transparent;
  border: 1px solid #37dcf2;
  border-radius: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  font-variant-ligatures: none;

  ${Media.tablet} {
    padding: 8px 20px;
  }
`;

const PowerImage = styled.img`
  margin-left: 12px;
`;
