import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperClass } from "swiper";
import styled from "styled-components";
import { NFT, tokenMap } from "../../../placeholders/tokens";
import { Media } from "../../../styles";
import _ from "lodash";
import { scrollbar } from "../../../styles/scrollbar";
import { useSearch } from "../../../hooks/useSearch";
import grid from "../../../assets/icons/grid.svg";
import { Modal } from "../../../components/Modal/Modal";
import { SearchContainer } from "../../../components/TokensModal/TokensModal";
import { SearchInput } from "../../../components/TokensModal/SearchInput";

interface INFTsSwiperProps {
  nftTokens: any[];
  navitagionTransform?: { left: string; right: string };
  maxWidth?: string;
  isConfirmation?: boolean;
}
export const NFTsSwiper = ({
  nftTokens,
  navitagionTransform,
  maxWidth,
  isConfirmation,
}: INFTsSwiperProps) => {
  const [canSwipe, setCanSwipe] = useState<{
    isBeginning: boolean;
    isEnd: boolean;
  }>({
    isBeginning: true,
    isEnd: false,
  });
  const navigationPrevRef = React.useRef(null);
  const swiperRef = React.useRef<{ swiper: SwiperClass }>(null);
  const navigationNextRef = React.useRef(null);

  const [showModal, setShowModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const nextSlide = () => {
    swiperRef.current?.swiper?.slideNext();
    setCanSwipe({ ...canSwipe, isBeginning: false, isEnd: swiperRef.current?.swiper.isEnd! });
  };

  const prevSlide = () => {
    swiperRef.current?.swiper?.slidePrev();
    setCanSwipe({ ...canSwipe, isBeginning: swiperRef.current?.swiper.isBeginning!, isEnd: false });
  };

  const getMaxNFTsInRow = () => {
    const breakpoints = [
      { width: 360, count: 2 },
      { width: 430, count: 3 },
      { width: 500, count: 4 },
      { width: 570, count: 5 },
      { width: 640, count: 6 },
      { width: 768, count: 7 },
    ];

    const windowWidth = window.innerWidth;
    const matchingBreakpoint = breakpoints.find(
      ({ width }) => windowWidth < width
    );

    return matchingBreakpoint ? matchingBreakpoint.count : maxWidth ? 9 : isConfirmation ? 5 : 8;
  };

  const getMaxNFTsToDisplay = () => {
    const amount = getMaxNFTsInRow();
    return Math.min(amount + 1, amount + 2, nftTokens.length);
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleSearchInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const filteredCollectionItems = useSearch(
    nftTokens as any,
    ["id"],
    searchValue
  );

  return (
    <TokensContainer
      navitagionTransform={navitagionTransform}
      maxWidth={maxWidth}
      isConfirmation={isConfirmation}
    >
      {nftTokens.length >= getMaxNFTsToDisplay() ? (
        <>
          <div
            className={`button-prev ${canSwipe.isBeginning ? "disabled" : ""}`}
            ref={navigationPrevRef}
            onClick={prevSlide}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33268 8H12.666"
                stroke="white"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301"
                stroke="white"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className={`button-next ${canSwipe.isEnd ? "disabled" : ""}`}
            ref={navigationNextRef}
            onClick={nextSlide}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.33268 8H12.666"
                stroke="white"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.99935 12.6663L12.666 7.99967L7.99935 3.33301"
                stroke="white"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <Swiper
            ref={swiperRef}
            slidesPerView={"auto"}
            slidesPerGroup={1}
            className="swiper-container"
            onUpdate={(swiper) => {
              setCanSwipe({
                ...canSwipe,
                isBeginning: swiper.isBeginning,
                isEnd: swiper.isEnd,
              });
            }}
            onReachBeginning={() => {
              setCanSwipe({ ...canSwipe, isBeginning: true, isEnd: false });
            }}
            onReachEnd={() => {
              setCanSwipe({ ...canSwipe, isBeginning: false, isEnd: true });
            }}
          >
            {nftTokens.slice(0, getMaxNFTsInRow()).map((token) => (
              <SwiperSlide key={token.name + token.id}>
                <Link
                  href={`https://opensea.io/assets/arbitrum/${tokenMap[token.symbol].address}/${token.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TokenSummary>
                    <Image src={token.image} />
                    <TokenID>{'balance' in token ? token.name : '#' + token.id}</TokenID>
                    {!isConfirmation && token.balance > 0 && <TokenBalance>x{token.balance}</TokenBalance>}
                  </TokenSummary>
                </Link>
              </SwiperSlide>
            ))}
            {nftTokens.length > getMaxNFTsInRow() && (
              <SwiperSlide>
                <TokenSummary>
                  <ViewAllButton data-testid="view-all-nfts-btn" onClick={toggleModal}>
                    <img src={grid} alt="Grid" />
                  </ViewAllButton>
                  <TokenID>View all</TokenID>
                </TokenSummary>
              </SwiperSlide>
            )}
          </Swiper>
        </>
      ) : (
        <>
          {nftTokens.map((token) => (
            <SwiperSlide key={token.name + token.id}>
              <Link
                href={`https://opensea.io/assets/arbitrum/${tokenMap[token.symbol].address}/${token.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TokenSummary>
                  <Image src={token.image} />
                  <TokenID>{'balance' in token ? token.name : '#' + token.id}</TokenID>
                  {!isConfirmation && token.balance > 0 && <TokenBalance>x{token.balance}</TokenBalance>}
                </TokenSummary>
              </Link>
            </SwiperSlide>
          ))}
        </>
      )}
      <StyledModal
        isVisible={showModal}
        onClose={toggleModal}
        title={"All Wrapped NFTs"}
        isConfirmation={isConfirmation ? true : false}
      >
        <SearchContainer>
          <SearchInput
            type="text"
            placeholder="Type NFT #..."
            value={searchValue}
            onChange={handleSearchInput}
          />
        </SearchContainer>
        <GalleryContainer itemCount={nftTokens.length}>
          {filteredCollectionItems &&
            filteredCollectionItems.map((nft: NFT) => (
              <Link
                href={`https://opensea.io/assets/arbitrum/${tokenMap[nft.symbol].address}/${nft.id}`}
                target="_blank"
                rel="noopener noreferrer"
                key={nft.id}
              >
                <GalleryItem key={nft.id}>
                  <ImageContainer>
                    <ToucanImage src={nft.image} alt="ToucanImage" />
                  </ImageContainer>
                  <Row>
                    <TokenID>#{nft.id}</TokenID>
                  </Row>
                </GalleryItem>
              </Link>
            ))}
        </GalleryContainer>
      </StyledModal>
    </TokensContainer>
  );
};

const Link = styled.a`
  color: white;
`;

const StyledModal = styled(Modal)`
  ${({ isConfirmation }) =>
    isConfirmation &&
    `
    height: 547px;
    max-width: 520px;

    ${Media.mobile} {
      height: 95%;
      max-width: 100%;
      margin-top: 5.5%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  `};

  ${({ isConfirmation }) =>
    !isConfirmation &&
    `
    height: 580px;
    max-width: 620px;

    ${Media.mobile} {
      height: 90%;
      max-width: 100%;
      margin-top: 9.5%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
  `};
`;

const ViewAllButton = styled.button`
  display: block;
  width: 64px;
  height: 64px;
  color: #000e47;
  border-radius: 12px;
  border: 1px solid #37dcf2;
  object-fit: cover;

  > img {
    width: 32px;
    height: 32px;
  }
`;

const Row = styled.div`
  align-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5px;
  > div {
    align-items: center;
    display: flex;
  }
`;

const GalleryContainer = styled.div<{ itemCount: number }>`
  display: grid;
  gap: 16px 12px;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  justify-items: start;
  align-content: flex-start;
  justify-content: start;
  grid-template-columns: repeat(4, calc(25% - calc(3 * 12px / 4)));
  align-items: center;
  ${scrollbar()}
  max-height: calc(100% - 100px);
  height: 100%;
  padding: 0px;

  ${Media.mobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }

  ${Media.smallMobile} {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }
`;

const GalleryItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;
  border-radius: 16px;
  transition: border-color 0.3s ease;
  background-color: #171b33;
  max-height: 100%;
  max-width: 100%;
  padding: 10px 10px 0px 10px;

  &:hover {
    border-color: #37dcf2;
  }

  ${Media.mobile} {
    max-width: 90%;
  }

  ${Media.smallMobile} {
    max-width: 90%;
  }
`;

const ImageContainer = styled.div`
  display: flex;

  &:before {
    content: "";
    display: block;
    padding-top: 100%;
    width: 0px;
  }
`;

const ToucanImage = styled.img`
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;

const TokensContainer = styled.div<{
  navitagionTransform?: { left: string; right: string };
  maxWidth?: string;
  isConfirmation?: boolean;
}>`
  max-width: ${({ maxWidth, isConfirmation }) =>
    maxWidth ? maxWidth + "px" : isConfirmation ? "100%" : "586px"};
  display: flex;
  position: relative;

  ${Media.desktop} {
    max-width: ${({ maxWidth, isConfirmation }) =>
      maxWidth ? maxWidth + "px" : isConfirmation ? "100%" : "500px"};
  }

  ${Media.smallDesktop} {
    max-width: ${({ maxWidth, isConfirmation }) =>
      maxWidth ? maxWidth + "px" : isConfirmation ? "100%" : "410px"};
  }

  ${Media.tablet} {
    max-width: ${({ maxWidth, isConfirmation }) =>
      maxWidth
        ? maxWidth + "px"
        : isConfirmation
        ? "100%"
        : "calc(100vw - 80px)"};
  }

  ${Media.mobile} {
    max-width: ${({ maxWidth, isConfirmation }) =>
      isConfirmation || maxWidth ? "100%" : "calc(100vw - 80px)"};
    padding: ${({ isConfirmation }) => (isConfirmation ? "5px" : "none")};

    .button-prev {
      transform: translate(5px, -50%);
    }
    .button-next {
      transform: translate(-5px, -50%);
    }
  }

  .swiper {
    margin-inline: unset;
  }

  .swiper-slide {
    width: 68px !important;
    &:not(:last-child) {
      margin-right: ${({ maxWidth }) => maxWidth ? "18px" : "12px"};
    }

    ${Media.tablet}{
      &:not(:last-child) {
        margin-right: 9px;
      }
    }

    ${Media.mobile}{
      &:not(:last-child) {
        margin-right: 7px;
      }
    }
  }

  .button-prev,
  .button-next {
    position: absolute;
    top: 50%;
    background-color: #171b33;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    z-index: 9999;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &.disabled {
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }
    &:hover {
      background-color: #1e2239;
    }
  }
  .button-prev {
    left: 0;
    transform: ${({ navitagionTransform }) =>
      `translate(${
        navitagionTransform?.left ? navitagionTransform.left : "-34"
      }px, -100%)`};
    right: auto;
    svg {
      transform: rotate(180deg);
    }
  }
  .button-next {
    right: 0;
    transform: ${({ navitagionTransform }) =>
      `translate(${
        navitagionTransform?.right ? navitagionTransform.right : "24"
      }px, -100%)`};
    left: auto;
  }
`;

const Image = styled.img`
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 12px;
  object-fit: cover;
`;

const TokenSummary = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
`;

const TokenID = styled.span`
  text-align: center;
  margin-top: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`;

const TokenBalance = styled.div`
  font-size: 12px;
  line-height: 15px;
  color: #7d7d97;
`;
