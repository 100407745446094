import { createSlice } from '@reduxjs/toolkit'

interface MetricsState {
    data: any,
}

const initialState : MetricsState = {data: {}}

const metricsSlice = createSlice({
  name: 'metrics',
  initialState,
  reducers: {
    updateMetrics: (state, action) => {
        return {
            ...state,
            data: action.payload.data
        }
    },
    clearMetrics: () => {
        return initialState
    },
  }
})

export const { updateMetrics, clearMetrics } = metricsSlice.actions

export default metricsSlice.reducer