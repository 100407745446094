import React, { Fragment, useMemo } from "react";
import { useExpanded, useFilters, useSortBy, useTable } from "react-table";
import styled from "styled-components";
import { SortIcon } from "@/components/Icons/SortIcon";
import { MultiplierCell } from "../Rewards/components/Points/components/TableCells/MultiplierCell";
import { PointsTokenPreviewCell } from "../Rewards/components/Points/components/TableCells/PointsTokenPreviewCell";
import { ValueCell } from "../Rewards/components/Points/components/TableCells/ValueCell";
import { ButtonSecondary } from "@/components/Buttons/Button";
import { Media } from "@/styles";
import { useNavigate, NavigateFunction } from "react-router";
import { StatusBullet, StatusContainer } from "../LBP/StatusBullet";
import { formatDisplay } from "@/utils/formatDisplay";
import { isExternalLPToken, isLBPToken, isShellV2Token, tokenMap } from "@/placeholders/tokens";
import { convertToSlug } from "@/types/ChartTypes";
import { generateBuySellTokens } from "../Rewards/components/Points/components/PoolSubRow";

interface SortableTableProps {
  data: Array<object>;
  renderRowSubComponent?: any;
  activeView?: string;
}

export const getSlugForStatisticsPage = (token: any) => {
    const name = token?.name;
    const slug = name?.includes("+") ? name : convertToSlug(name);
    return `/statistics/${slug}`;
}

export const redirectToPool = (token: any, navigate: NavigateFunction) => {
    if (isLBPToken(token)) {
        navigate(`/lbp/${token.symbol}`);
    } else if(isExternalLPToken(token)){
        if(isShellV2Token(token)){
            navigate(getSlugForStatisticsPage(token));
        } else {
            navigate('/trade', { state: generateBuySellTokens(token, true) })
        }
    } else {
        navigate(getSlugForStatisticsPage(token));
    }
}

export const PoolsTable = ({ data, activeView, renderRowSubComponent }: SortableTableProps) => {
  const navigate = useNavigate();

  const columns = useMemo(
    () =>
      activeView === "LP Tokens"
        ? [
            {
              Header: "",
              accessor: "token",
              Cell: ({ row }: any) => <PointsTokenPreviewCell row={row} />,
            },
            {
                Header: "APY",
                accessor: "apy",
                Cell: ({ row }: any) => (
                  <APY color={row.original.apy > 0 ? "#7ADEB9" : "red"}>
                    {`${row.original.apy > 1 ? formatDisplay(((row.original.apy - 1) * 100).toString(), 2) : '--'}%`}
                  </APY>
                ),
            },
            {
              Header: "Liquidity",
              accessor: "liquidity",
              Cell: ValueCell,
            },
            {
              Header: "24h Vol",
              accessor: "volume",
              Cell: ValueCell,            
            },
            {
              Header: "Multiplier",
              accessor: "multiplier",
              Cell: MultiplierCell,
            },
            {
              id: "expander",
              accessor: "expander",
              Cell: ({ row }: any) => (
                <div style={{marginLeft: 'auto'}}><img src="../assets/icons/chevron-left.svg"/></div> 
              ),
            },
          ]
        : [
            {
              Header: "Token",
              accessor: "token",
              Cell: ({ row }: any) => <PointsTokenPreviewCell row={row} />,
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: ({ row }: any) => (
                <div style={{display: 'flex', justifyContent: 'center'}}><StatusBullet status={row.original.status} /></div>
              ),
            },
            {
              Header: "Market Cap",
              accessor: "marketCap",
              Cell: ValueCell
            },
            {
              Header: "Volume",
              accessor: "volume",
              Cell: ValueCell
            },
            {
              id: "expander",
              accessor: "expander",
              Cell: ({ row }: any) => (
                <div style={{marginLeft: 'auto'}}><img src="../assets/icons/chevron-left.svg"/></div> 
              ),
            },
          ],
    [activeView]
  );

  const defaultColumn = React.useMemo(() => ({ Filter: <></> }), []);

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultColumn,
    },
    useFilters,
    useSortBy,
    useExpanded
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    visibleColumns,
  } = tableInstance;

  const handleRowClick = (token: any) => redirectToPool(token, navigate);

  return (
    <Table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column, index) => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {
                  <>
                    {column.render("Header")}
                    {column.canSort && (
                      <SortIcon
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    )}
                    {column.canFilter && <div>{column.render("Filter")}</div>}
                  </>
                }
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          const tokenData: any = row.original;

          return (
            <Fragment key={row.id}>
              <TableRow
                {...row.getRowProps()}
                onClick={() => tokenData.visible && handleRowClick(tokenMap[tokenData.name])}
                className={
                  row.isExpanded
                    ? "expanded"
                    : tokenData.visible
                    ? "hoverable"
                    : ""
                }
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </TableRow>
              {row.isExpanded && (
                <TableSubRow>
                  <td colSpan={visibleColumns.length}>
                    {renderRowSubComponent({ row })}
                  </td>
                </TableSubRow>
              )}
            </Fragment>
          );
        })}
      </tbody>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;

  th {
    padding-bottom: 12px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #7d7d97;
    width: 12%;

    & > svg {
      margin-left: 8px;
    }

    &:first-child {
      padding-left: 24px;
      // pointer-events: none;
      // user-select: none;
      opacity: 0;
      width: 40%;
      text-align: start;
    }

    &:last-child {
      pointer-events: none;
      user-select: none;
      opacity: 0;
      width: 0%;
    }
  }

  td {
    border-top: 1px solid #1e2239;
    border-bottom: 1px solid #1e2239;
    background: #151530;
  }

  td:first-child {
    border-left: 1px solid #1e2239;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  td:last-child {
    border-right: 1px solid #1e2239;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;

const TableRow = styled.tr`
  position: relative;
  z-index: 1;

  &.expanded:hover {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  td {
    padding: 26px 8px 22px 0px;
    vertical-align: middle;
    text-align: center;

    ${StatusContainer} {
      width: fit-content;
    }
  }

  td:first-child {
    padding-left: 24px;
    text-align: left;
  }

  td:last-child {
    padding-right: 24px;
    text-align: right;
  }

  &.expanded td {
    border-bottom: none;
  }

  &.expanded td:first-child {
    border-bottom-left-radius: 0;
  }

  &.expanded td:last-child {
    border-bottom-right-radius: 0;
  }

  &.hoverable:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.hoverable:hover td:first-child {
    padding-left: 23px;
  }
  &.hoverable:hover td:last-child {
    padding-right: 23px;
  }

  &.expanded:hover td {
    cursor: pointer;
    padding-top: 25px;
    padding-bottom: 21px;
    border-color: #2c5173;
    border-width: 2px;
    border-bottom: 2px solid #2c5173;
  }
  &.expanded:hover td:first-child {
    padding-left: 23px;
  }
  &.expanded:hover td:last-child {
    padding-right: 23px;
  }
`;

const TableSubRow = styled.tr`
  transform: translateY(-10px);

  td {
    background: #10102a;
  }

  & td:first-child {
    border-top-left-radius: 0;
  }

  & td:last-child {
    border-top-right-radius: 0;
  }
`;

const TableButton = styled(ButtonSecondary)`
  width: fit-content;
  height: 48px;
  border-width: 2px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-inline: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;

  svg {
    transition: all 0.3s;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  ${Media.tablet} {
    width: 100%;
  }
`;

const APY = styled.div<{ color: string }>`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${({ color }) => color};
`;
