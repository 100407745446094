import { createSlice } from '@reduxjs/toolkit'

interface TransactionsState {
    transactions: {[address: string]: any},
    transactionCounts: Record<string, number>,
    interactions: Record<string, any>,
}

const initialState : TransactionsState = {transactions: {}, transactionCounts: {}, interactions: {}}

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    addTransaction: (state, action) => {
        const { address, skip, data } = action.payload;
        const newTransactions = {
            ...state.transactions,
            [address]: {
                ...state.transactions[address],
                [skip]: data,
            },
        };

        return {
            ...state,
            transactions: newTransactions
        }
    },
    clearUserTransactions: (state, action) => {
        const newTransactions = {
            ...state.transactions,
            [action.payload.user]: {}
        };
        return {
            ...state,
            transactions: newTransactions
        }
    },
    addTransactionCount: (state, action) => {
        let newTransactionCounts = {...state.transactionCounts}
        newTransactionCounts[action.payload.address] = action.payload.count
        return {
            ...state,
            transactionCounts: newTransactionCounts
        }
    },
    addInteraction: (state, action) => {
        let newInteractions = {...state.interactions}
        newInteractions[action.payload.hash] = action.payload.data
        return {
            ...state,
            interactions: newInteractions
        }
    },
    clearTransactions: () => {
        return initialState
    },
  }
})

export const { addTransaction, clearUserTransactions, addTransactionCount, addInteraction, clearTransactions } = transactionsSlice.actions

export default transactionsSlice.reducer