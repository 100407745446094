import { useState, useEffect } from "react";
import { NFT } from "../placeholders/tokens";

export type orderByType = "id" | "rarity" | null;
export type orderType = "asc" | "desc" | null;

export const useNFTSort = (
  list: NFT[],
  order: orderType,
  orderBy: orderByType
) => {
  const [sortedList, setSortedList] = useState(list);

  useEffect(() => {
    let sorted: NFT[];
    if (order === "asc" && orderBy) {
      sorted = [...list].sort((a: NFT, b: NFT) => a[orderBy] - b[orderBy]);
      setSortedList(sorted);
    } else if (order === "desc" && orderBy) {
      sorted = [...list].sort((a: NFT, b: NFT) => b[orderBy] - a[orderBy]);
      setSortedList(sorted);
    } else {
      setSortedList(list);
    }
  }, [list, order, orderBy]);

  return sortedList;
};
