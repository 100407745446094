import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { isDefaultShellToken, isExternalLPToken, isLPToken, isNFTCollection, isShellToken, isShellV2Token, NFT, NFT1155, NFTCollection, ShellToken, Token, tokenMap } from "../../../placeholders/tokens";
import { ModalTriggerButton } from "../../../components/Modal/ModalTriggerButton";
import { useSearch } from "../../../hooks/useSearch";
import { List, ListItem, SearchContainer, Title, TokensModal, TokensModalHeader } from "../../../components/TokensModal/TokensModal";
import { TokenButton } from "../../../components/List/TokenButton";
import { erc20ABI, useAccount, useNetwork, useProvider } from "wagmi";
import { OceanABI } from "../../../constants/ABI/OceanABI";
import cross from "../../../assets/icons/cross.svg";
import { ETH_ADDRESS, OCEAN_ADDRESS, OLD_OCEAN_ADDRESS } from "../../../constants/addresses";
import { addBalance, addNFTBalance } from "../../../store/balancesSlice";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { formatDisplay } from "../../../utils/formatDisplay";
import { formatUnits } from "@ethersproject/units";
import { ContractCallResults, ContractCallContext } from "ethereum-multicall";
import { CloseButton } from "../../../components/Modal/Modal";
import { Collections } from "../../../components/Collections/Collections";
import { ButtonPrimary } from "../../../components/Buttons/Button";
import { SearchInput } from "../../../components/TokensModal/SearchInput";
import { ToggleSwitch } from "../../../components/ToggleSwitch/ToggleSwitch";
import { getTokenID } from "../../../utils/LiquidityGraph";
import { reduceString } from "../../../utils/reduceString";
import { getAllNFTs, getNFTs, multicall } from "../../../utils/nftHelpers";
import { removeLeadingZeros } from "../../../utils/ocean/utils";
import { BigNumber } from "@ethersproject/bignumber";
import { WRAPPED_NFT_MAP } from "../../../constants/wrappedNFTs";
import { CONNECTED_CHAIN } from "@/constants/chains";
import searchEyeLine from "@/assets/icons/search-eye-line.svg";
import { CustomDropdown } from "@/components/CustomDropdown/CustomDropdown";


export interface SelectTokenModalProps {
  selectedToken: Token | ShellToken | NFTCollection;
  onTokenSelect: (token: Token | ShellToken | NFTCollection) => void;
  filteredTokens: any[];
  filteredCollections: any[];
  disabledTokens: string[];
  isInputToken: boolean;
  selectedNFTs: (NFT | NFT1155)[];
  updateSelectedNFTs: any;
  otherNFTs: {[collection : string] : (NFT | NFT1155)[]};
  isShrunk: boolean;
  selectionLocked?: boolean;
}

export const SelectTokenModal = ({
  selectedToken,
  onTokenSelect,
  filteredTokens,
  filteredCollections,
  disabledTokens,
  isInputToken,
  selectedNFTs,
  updateSelectedNFTs,
  otherNFTs,
  isShrunk,
  selectionLocked
}: SelectTokenModalProps) => {
  const { address: walletAddress, isConnected } = useAccount();
  const { chain: activeChain } = useNetwork();
  const validChain = activeChain?.name == CONNECTED_CHAIN
  type ViewsType = "Tokens" | "LP Tokens" | "NFTs";
  const views: ViewsType[] = ["Tokens", "LP Tokens", `NFTs`];
  const allLPTokens = filteredTokens.filter((token) => isLPToken(token))

  const [activeView, setActiveView] = useState<ViewsType>(views[0]);

  const provider = useProvider();
  
  const [isVisible, setIsVisible] = useState(false);

  const [tokensLoading, setTokensLoading] = useState(false);
  const [nftsLoading, setNFTsLoading] = useState(false);

  const [sortedTokens, setSortedTokens] = useState(filteredTokens.filter((token) => !isLPToken(token)))
  const [sortedShellTokens, setSortedShellTokens] = useState(allLPTokens)
  const [protocolOptions, setProtocolOptions] = useState<string[]>([])

  const closeModal = () => {
    setIsVisible(false);
  };

  const titleMap: { [key in ViewsType]: string } = {
    Tokens: "Select Token",
    "LP Tokens": "Select LP Token",
    NFTs: "Select NFT Collection",
  };

  
  const [tokenSearchValue, setTokenSearchValue] = useState("");
  const searchableTokenProperties = ["symbol", "name"];
  const [isToggleCheckedState, setIsToggleCheckedState] = useState(false);
  const tokenItemsMemoized = React.useMemo(
    () =>
      sortedTokens.filter(
        ({ wrapped }) => wrapped === isToggleCheckedState
      ),
    [isToggleCheckedState, sortedTokens]
  );
  const searchTokens = useSearch(
    tokenItemsMemoized,
    searchableTokenProperties,
    tokenSearchValue
  );
  const searchShellTokens = useSearch(sortedShellTokens, searchableTokenProperties, tokenSearchValue)

  const userBalances = useAppSelector((state) => state.balances.balances);
  const userNFTBalances = useAppSelector((state) => state.balances.nftBalances);

  const dispatch = useAppDispatch();

  const onTokenClick = (newToken: Token) => {
    onTokenSelect(newToken);
    if(isNFTCollection(selectedToken))
        updateSelectedNFTs(selectedToken.symbol, null)
    
    closeModal();
  };

  const openModal = async () => {
    if(selectionLocked) return
    setIsVisible(true);
    setIsToggleCheckedState(
      selectedToken.wrapped && !isLPToken(selectedToken)
    );

    if (isNFTCollection(selectedToken)) {
      setActiveView("NFTs");
    } else if (isLPToken(selectedToken)) {
      setActiveView("LP Tokens");
    }
  };

  const getTokenBalances = async (connected: any) => {
    setTokensLoading(true);

    const tokenBalancePairs: any[] = [];
    const idToToken: any = {};

    const contractCallContext: ContractCallContext[] = [
        {
          reference: "Ocean v3",
          contractAddress: OCEAN_ADDRESS,
          abi: OceanABI,
          calls: [
            {
              reference: "balanceOBatch",
              methodName: "balanceOfBatch",
              methodParameters: [[], []],
            },
          ],
        },
        {
            reference: "Ocean v2",
            contractAddress: OLD_OCEAN_ADDRESS,
            abi: OceanABI,
            calls: [
              {
                reference: "balanceOBatch",
                methodName: "balanceOfBatch",
                methodParameters: [[], []],
              },
            ],
          },
    ];

    let query = false;

    for (const token of filteredTokens) {
      const tokenKey = token.wrapped || isShellV2Token(token) ? token.oceanID : token.address;

      if (!connected) {
        dispatch(
          addBalance({
            address: tokenKey,
            amount: "0",
          })
        );
        tokenBalancePairs.push({ token: token, balance: 0 });
        continue;
      } else if (userBalances[tokenKey]) {
        tokenBalancePairs.push({
          token: token,
          balance: parseFloat(userBalances[tokenKey]),
        });
        continue;
      }

      query = true;
        
      if (token.wrapped) {
        contractCallContext[0].calls[0].methodParameters[0].push(walletAddress);
        contractCallContext[0].calls[0].methodParameters[1].push(token.oceanID);
        idToToken[tokenKey] = token;
      } else if(isShellV2Token(token)) {
        contractCallContext[1].calls[0].methodParameters[0].push(walletAddress);
        contractCallContext[1].calls[0].methodParameters[1].push(token.oceanID);
        idToToken[tokenKey] = token;
      } else if (token.address != ETH_ADDRESS) {
        contractCallContext.push({
          reference: token.symbol,
          contractAddress: token.address,
          abi: erc20ABI as any,
          calls: [
            {
              reference: "balanceOf",
              methodName: "balanceOf",
              methodParameters: [walletAddress],
            },
            {
              reference: "decimals",
              methodName: "decimals",
              methodParameters: [],
            },
          ],
        });
        idToToken[tokenKey] = token;
      } else {

        const ethBalance = await provider?.getBalance(walletAddress ?? "0xNull").then((result: any) => {
          return formatUnits(result);
        }).catch(() => {
          return "0";
        });

        dispatch(addBalance({ address: token.address, amount: ethBalance }));  
        tokenBalancePairs.push({ token: token, balance: parseFloat(ethBalance!)});
      }
    }

    if (connected && query) {
      const results: ContractCallResults = await multicall.call(
        contractCallContext
      );  

      for (const callID of Object.keys(results.results)) {
        let tokenID, newBalance;
        if (callID.includes("Ocean")) {
          results.results[callID].callsReturnContext[0].returnValues.forEach(
            (balance, index) => {
              tokenID =
                results.results[callID].callsReturnContext[0]
                  .methodParameters[1][index];
              newBalance = formatUnits(BigNumber.from(balance ?? 0));
              dispatch(addBalance({ address: tokenID, amount: newBalance }));
              tokenBalancePairs.push({
                token: idToToken[tokenID],
                balance: parseFloat(newBalance),
              });
            }
          );
        } else {
          tokenID = tokenMap[callID].address;
          newBalance = formatUnits(
            BigNumber.from(results.results[callID].callsReturnContext[0].returnValues[0]?.hex ?? 0),
            results.results[callID].callsReturnContext[1].returnValues ?? 18
          );
          dispatch(addBalance({ address: tokenID, amount: newBalance }));
          tokenBalancePairs.push({
            token: idToToken[tokenID],
            balance: parseFloat(newBalance),
          });
        }
      }
    }

    tokenBalancePairs.sort((a: { balance: number; }, b: { balance: number; }) => (a.balance < b.balance ? 1 : -1));
    const otherLPTokens = tokenBalancePairs.filter((entry: { token: any; }) => entry.token.tokenType !== 'Shell');
    const shellLPTokens = tokenBalancePairs.filter((entry: { token: any; }) => entry.token.tokenType === 'Shell');
    const sortedTokenBalancePairs = [...otherLPTokens, ...shellLPTokens];
    const newSortedTokens = sortedTokenBalancePairs.map((entry: { token: any; }) => entry.token);

    const selectedIndex = newSortedTokens.findIndex((token) => token.name === selectedToken.name);
    if (selectedIndex > -1) {
      const token = newSortedTokens.splice(selectedIndex, 1)[0];
      newSortedTokens.unshift(token);
    }

    setSortedTokens(newSortedTokens.filter((token: any) => !isLPToken(token)));
    setSortedShellTokens(newSortedTokens.filter((token: any) => isLPToken(token)));

    setTokensLoading(false)
      
  }

  const getNFTBalances = async (connected : boolean) => {
    setNFTsLoading(true)

    let allUserNFTS = []
    let userOceanNFTs = []

    let queriedAll = false
    let queriedOcean = false

    for (const collection of filteredCollections) {
        if (!connected) {
          const emptyNFTs: { id: number; balance: number; }[] = []
          if(collection.is1155){
            Array.from(Array(collection.maxSupply).keys()).forEach((id) => {
                emptyNFTs.push({
                    id: id,
                    balance: 0
                })
            })
          }
          dispatch(
            addNFTBalance({
              collection: collection.symbol,
              items: emptyNFTs,
            })
          );
          continue;
        } else if(userNFTBalances[collection.symbol]){
            continue
        }

        if(collection.wrapped && !queriedOcean){
            userOceanNFTs = (await getNFTs(walletAddress, OCEAN_ADDRESS))
            queriedOcean = true
        } else if(!queriedAll){
            allUserNFTS = await getAllNFTs(walletAddress, filteredCollections.filter((collection) => !collection.wrapped))
            queriedAll = true
        }

        if(collection.is1155){

            if(collection.wrapped){
                const wrappedIDs = WRAPPED_NFT_MAP[collection.symbol]

                const userWrappedNFTs : any[] = []
                userOceanNFTs.forEach((userOceanNFT : any) => {
                    const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
                    if(wrappedIDs[oceanID]) {
                        userWrappedNFTs.push({
                            id: parseInt(wrappedIDs[oceanID][0]),
                            balance: parseInt(userOceanNFT.balance)
                        })
                    }    
                })

                const nonZeroBalanceIDs = new Set(userWrappedNFTs.map((nft) => nft.id))
                Array.from(Array(collection.maxSupply).keys()).forEach((id) => {
                    if(!nonZeroBalanceIDs.has(id)){
                        userWrappedNFTs.push({
                            id: id,
                            balance: 0
                        })
                    }
                })

                dispatch(
                    addNFTBalance({
                        collection: collection.symbol,
                        items: userWrappedNFTs,
                    })
                );
            } else {

                const userNFTs = allUserNFTS[collection.symbol].map((nft : any) => {
                    return {
                        id: parseInt(nft.tokenId),
                        balance: parseInt(nft.balance)
                    }
                });
                
                const nonZeroBalanceIDs = new Set(userNFTs.map((nft : any) => nft.id))

                Array.from(Array(collection.maxSupply).keys()).forEach((id) => {
                    if(!nonZeroBalanceIDs.has(id)){
                        userNFTs.push({
                            id: id,
                            balance: 0
                        })
                    }  
                })

                dispatch(
                    addNFTBalance({
                        collection: collection.symbol,
                        items: userNFTs
                    })
                );
            }   
        } else {
            if(collection.wrapped){

                const wrappedIDs = WRAPPED_NFT_MAP[collection.symbol]

                const userWrappedNFTs : any[] = []
                userOceanNFTs.forEach((userOceanNFT : any) => {
                    const oceanID = removeLeadingZeros(BigNumber.from(userOceanNFT.tokenId).toHexString())
                    if(wrappedIDs[oceanID]) userWrappedNFTs.push(parseInt(wrappedIDs[oceanID][0]))
                })

                dispatch(
                    addNFTBalance({
                        collection: collection.symbol,
                        items: userWrappedNFTs,
                    })
                );

            } else {
                const userNFTs = allUserNFTS[collection.symbol].map((nft : any) => parseInt(nft.tokenId));
                dispatch(
                    addNFTBalance({
                        collection: collection.symbol,
                        items: userNFTs,
                    })
                );
            }
        }
    }

    setNFTsLoading(false)

  }

  useEffect(() => {
    if(isVisible){
        if(activeView == 'NFTs'){
            // if(isNFTCollection(selectedToken)){
            //     setSelectedItems(selectedNFTs)
            // } else {
            //     getNFTBalances(isConnected && validChain)
            // }
            getNFTBalances(isConnected && validChain)
        } else {
            getTokenBalances(isConnected && validChain)
        }
    }
    
  }, [isVisible, activeView, walletAddress, isConnected])

  const textRight = (token: Token) => {
    const userBalance =
      userBalances[token.wrapped || isShellV2Token(token) ? token.oceanID ?? '' : token.address];

    if (isDefaultShellToken(token)) {
      return isInputToken ? "Remove Liquidity" : "Provide Liquidity";
    } else if (userBalance) {
      return formatDisplay(userBalance, 2);
    } else {
      return "0";
    }
  };

  const textColor = (token: Token) => {
    if (token.name === "Shell LP Token") {
      return isInputToken ? "red" : "green";
    } else if (token.wrapped) {
      return "blue";
    } else {
      return "";
    }
  };

  const filterLpTokens = (option: string) => {
    if(option == 'all'){
        setSortedShellTokens(allLPTokens)
    } else {
        setSortedShellTokens(allLPTokens.filter((el) => el?.tokenType === option))
    }
  }

  useEffect(() => {
    const newProtocolOptions = new Set<string>()
    allLPTokens.forEach((shellToken) => newProtocolOptions.add(shellToken.tokenType))
    setProtocolOptions([...newProtocolOptions])
  }, [])


  return (
    <>
      <StyledModalTriggerButton
        dataTestId={`${isInputToken ? "input" : "output"}-modal-trigger-btn`}
        onClick={selectionLocked ? () => {} : openModal}
        icon={selectedToken.icon}
        textLength={selectedToken.symbol.length}
        className={isShrunk ? 'shrunk' : ''}
      >
        {selectedToken.symbol}
      </StyledModalTriggerButton>
      <TokensModal
        isVisible={isVisible}
        onClose={closeModal}
        activeView={activeView}
        setActiveView={setActiveView}
        views={views}
      >
        {activeView === "NFTs" ? (
          <>
            <Collections
                onClose={closeModal}
                collectionsList={filteredCollections}
                selectedNFTs={selectedNFTs}
                updateSelectedNFTs={updateSelectedNFTs}
                otherNFTs={otherNFTs}
                isInputToken={isInputToken}
                selectedCollection={isNFTCollection(selectedToken) ? selectedToken : null}
                setSelectedCollection={onTokenClick}
                isLoading={nftsLoading}
                disabledTokens={disabledTokens}
            />
          </>
        ) : (
          <>
            <TokensModalHeader>
              <Title>{titleMap[activeView]}</Title>
              <CloseButton onClick={closeModal}>
                <img src={cross} alt="Close" />
              </CloseButton>
            </TokensModalHeader>
            <SearchContainer>
              <SearchInput
                value={tokenSearchValue}
                onChange={(event) => setTokenSearchValue(event.target.value)}
                placeholder="Type token name"
              />
              {activeView == 'Tokens' && 
                <ToggleSwitch
                    dataTestId={`wrapped-toggle-${isToggleCheckedState}`}
                    isChecked={isToggleCheckedState}
                    onChange={(event) =>
                    setIsToggleCheckedState(event.target.checked)
                    }
                >
                    Wrapped
                </ToggleSwitch>
              }
              {activeView == "LP Tokens" && 
              <CustomDropdown options={protocolOptions} onOptionChange={(option) => filterLpTokens(option)} />
              }
            </SearchContainer>
            {activeView === "Tokens" && searchTokens ? (
              <List>
              {searchTokens.map(
                (token, index) => {
                return (
                  <ListItem key={index}>
                    <TokenButton
                      dataTestId={`token-btn-${getTokenID(token)}`}
                      icon={token.icon}
                      title={getTokenID(token)}
                      subtitle={
                        activeView == "Tokens"
                          ? token.name
                          : reduceString(isShellToken(token) ? token.oceanID : token.address, 6, 4)
                      }
                      onClick={() => onTokenClick(token)}
                      textRight={textRight(token)}
                      color={textColor(token)}
                      isLoading={isDefaultShellToken(token) ? false : tokensLoading}
                      selected={token.name === selectedToken.name}
                      disabled={disabledTokens.includes(getTokenID(token))}
                      status={token.status}
                      tokenType={token?.tokenType}
                      protocolVersion={token?.protocolVersion}
                      fee={token?.fee}
                    />
                  </ListItem>
                );
              })}
            </List>
            ) : searchShellTokens.length > 0 ? (
              <List>
              {searchShellTokens.map(
                (token, index) => {
                return (
                  <ListItem key={index}>
                    <TokenButton
                      dataTestId={`token-btn-${getTokenID(token)}`}
                      icon={token.icon}
                      title={getTokenID(token)}
                      subtitle={
                        activeView == "Tokens"
                          ? token.name
                          : reduceString(isShellToken(token) ? token.oceanID : token.address, 6, 4)
                      }
                      onClick={() => onTokenClick(token)}
                      textRight={textRight(token)}
                      color={textColor(token)}
                      isLoading={isDefaultShellToken(token) ? false : tokensLoading}
                      selected={token.name === selectedToken.name}
                      disabled={disabledTokens.includes(getTokenID(token))}
                      status={token.status}
                      tokenType={token?.tokenType}
                      protocolVersion={token?.protocolVersion}
                      fee={token?.fee}
                    />
                  </ListItem>
                );
              })}
            </List>
            ) : (
              <EmptyStateContainer>
                <EmptyIconAndTextWrapper>
                  <EmptyIconWrapper>
                    <EmptyIcon src={searchEyeLine} alt="search icon" />
                  </EmptyIconWrapper>
                  <EmptyTextWrapper>
                    <EmptyTitle>
                    We have not found such an asset
                    </EmptyTitle>
                    <EmptySubtitle>
                    Validate the name or create a new LP Token.
                    </EmptySubtitle>
                  </EmptyTextWrapper>
                </EmptyIconAndTextWrapper>
                <CustomPrimaryButton>Create new LP token</CustomPrimaryButton>
              </EmptyStateContainer>
            )}
          </>
        )}
      </TokensModal>
    </>
  );
};

const ConfirmButton = styled(ButtonPrimary)`
  position: absolute;
  bottom: 12px;
  width: calc(100% - 48px);
  height: 62px;
  border-radius: 16px;
  box-shadow: 0px 4px 24px rgba(42, 212, 244, 0.4);
`;

const StyledModalTriggerButton = styled(ModalTriggerButton)<{
  textLength: number | undefined;
}>`
  ${({ textLength }) =>
    textLength &&
    textLength > 6 &&
    css`
      font-size: 24px;
    `};
`;

const EmptyStateContainer = styled.div`
height: 100%;
width: 100%;
display: flex;
flex-direction: column;
gap: 24px;
justify-content: flex-start;
align-items: center;
margin-top: 62px;
`;

const EmptyIconAndTextWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
justify-content: flex-start;
align-items: center;
`;

const EmptyTextWrapper = styled.div`
display: flex;
flex-direction: column;
gap: 8px;
justify-content: flex-start;
align-items: center;
`;

const EmptyTitle = styled.p`
color: #FFF;

/* Title 7 */
font-family: Inter;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: -0.6px;
`;

const EmptySubtitle = styled.p`
color: var(--grey-3, #7D7D97);
text-align: center;

/* Text 3/Regular */
font-family: Inter;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 19.6px */
`;

const EmptyIconWrapper = styled.div`
width: 80px;
height: 80px;
display: flex;
justify-content: center;
align-items: center;
background: #171B33;
border-radius: 50%;
`;

const EmptyIcon = styled.img`
width: 40px;
height: 40px;
`;


const CustomPrimaryButton = styled(ButtonPrimary)`
width: unset;
border-radius: 16px;
padding: 18px 30px;
height: unset;
`;
