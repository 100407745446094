import React, { ReactNode } from "react";
import styled from "styled-components";
import { Media } from "../../styles";
import { scrollbar } from "../../styles/scrollbar";
import cross from "../../assets/icons/cross.svg";
import { BasicModalProps, CloseButton, Modal } from "../Modal/Modal";
import { SearchInput } from "./SearchInput";

type ViewsType = "Tokens" | "LP Tokens" | "NFTs";

interface TabGroupProps {
  views: ViewsType[];
  activeView: string;
  setActiveView: (activeView: ViewsType) => void;
}

function TabGroup({ views, activeView, setActiveView }: TabGroupProps) {
  return (
    <ButtonGroup>
      {views.map((view) => (
        <Tab
          key={view}
          activeView={activeView === view}
          onClick={() => setActiveView(view)}
          data-testid={`tab-${view}`}
        >
          {view}
        </Tab>
      ))}
    </ButtonGroup>
  );
}

interface TokensModalProps extends BasicModalProps {
  children: ReactNode;
  list?: ReactNode[];
  title?: string;
  // searchValue: string;
  // setSearchValue: (value: string) => void;
  activeView: string;
  setActiveView: (activeView: ViewsType) => void;
  views: ViewsType[];
  button?: ReactNode;
}

export const TokensModal = ({
  children,
  isVisible,
  onClose,
  title,
  // searchValue, setSearchValue,
  activeView,
  setActiveView,
  views,
  list,
  ...props
}: TokensModalProps) => {
  return (
    <>
      <StyledModal
        maxWidth="800"
        isVisible={isVisible}
        onClose={onClose}
        hideCloseButton
        header={
          <>
            <TabGroup
              views={views}
              activeView={activeView}
              setActiveView={setActiveView}
            />
          </>
          // <ModalSearchInput
          //   value={searchValue}
          //   onChange={(event) => setSearchValue(event.target.value)}
          //   placeholder="Type token name"
          // />
        }
        {...props}
      >
        {children}
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  height: 669px;
  padding: 24px;

  ${Media.mobile} {
    height: 100%;
    padding: 16px;
  }
`;

// const ModalSearchInput = styled(SearchInput)`
//   margin-top: 19px;
// `;

export const List = styled.ul`
  margin-top: 25px;
  padding: 3px 12px 3px 3px;
  overflow-y: auto;
  ${scrollbar()}
`;

export const ListItem = styled.li`
  & + & {
    margin-top: 10px;
    ${Media.mobile} {
      margin-top: 8px;
    }
  }
`;

const ButtonGroup = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;

  &:before {
    content: "";
    position: absolute;
    bottom: 0px;
    width: calc(100% + 48px);
    height: 1px;
    background: #292941;
  }
`;

const Tab = styled.button<{ activeView: boolean }>`
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 10px;
  cursor: pointer;
  opacity: 0.6;
  background: transparent;
  color: white;
  border: 0;
  outline: 0;
  z-index: 0;
  ${({ activeView }) =>
    activeView &&
    `
    border-bottom: 1px solid #37DCF2;
    opacity: 1;
    `}
  ${Media.mobile} {
    width: unset;
    padding-bottom: 16px;
    padding-inline: 0;
  }
`;

export const TokensModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0 16px;
`;

export const SearchContainer = styled(TokensModalHeader)`
  align-items: flex-end;
  margin-top: 8px;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: #ffffff;
`;
