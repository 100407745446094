import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './GlobalStyle';
import { WagmiProvider } from './WagmiProvider';
import { GeoProvider } from '@/components/Overlays/GeoProvider';

interface Props {
  children: ReactNode;
}

export function Providers(props: Props) {
  return (
    <WagmiProvider>
      <GeoProvider>
        <BrowserRouter>
          <GlobalStyle />
          {props.children}
        </BrowserRouter>
      </GeoProvider>
    </WagmiProvider>
  );
}