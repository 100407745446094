import React from "react";
import styled, { css } from "styled-components";
import angleDownIcon from "../../assets/icons/angle-down.svg";
import disabledAngleDownIcon from "../../assets/icons/disabled-angle-down.svg";
import { Media } from "../../styles";

interface ModalTriggerButtonProps {
  onClick: () => Promise<void> | void;
  icon?: string;
  children?: string | JSX.Element;
  className?: string;
  dataTestId?: string;
}

export const ModalTriggerButton = ({
  dataTestId,
  onClick,
  icon,
  children,
  className,
}: ModalTriggerButtonProps) => {
  const selectionLocked = typeof onClick === "function" && onClick.toString().trim() === "() => {}";
  return (
    <TriggerButton
      data-testid={dataTestId}
      onClick={onClick}
      className={className + (selectionLocked ? 'locked' : '')}
    >
      {icon && <img src={icon} alt="logo" />}
      <TriggerButtonText className={className}>{children}</TriggerButtonText>
    </TriggerButton>
  );
};

const TriggerButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  max-width: 316px;
  height: 74px;
  padding: 16px 48px 16px 20px;
  border: 1px solid #292941;
  border-radius: 16px;
  background: #1E2239 url(${angleDownIcon}) right 20px center no-repeat;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: -0.03em;
  color: #ffffff;

  img {
    width: 40px;
    height: 40px;
    margin-right: 12px;
  }

  &:hover,
  &:focus,
  &:focus-within {
    border-color: #2c5173;
  }

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      width: fit-content;
      min-width: 68px;
      max-width: unset;
      height: 48px;
      padding: 10px;
      background: #1E2239 url(${angleDownIcon}) right 8px center no-repeat;
  
      img {
        width: 24px;
        height: 24px;
        margin-right: 20px;
      }
    `
  };

  ${props =>
    props.className?.includes('locked') &&
    css`
      background: #1E2239 url(${disabledAngleDownIcon}) right 8px center no-repeat;
      pointer-events: none;
      &:hover,
      &:focus,
      &:focus-within {
        border-color: transparent;
      }
    `
  };
`;

export const TriggerButtonText = styled.span`
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${props =>
    props.className?.includes('shrunk') &&
    css`
      display: none;
    `
  };
`;
