import { createSlice } from '@reduxjs/toolkit'
interface State {
    pools: Record<string, any>,
    charts: Record<string, any>,
    all: any[]
}

const initialState : State = {pools: {}, charts: {}, all: []}

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
      addPool: (state, action) => {
          let newPools = {...state.pools}
          newPools[action.payload.name] = action.payload.data
          return {
              ...state,
              pools: newPools
          }
      },
      addPoolChart: (state, action) => {
        let newCharts = {...state.charts}
        newCharts[action.payload.name] = action.payload.data
        return {
            ...state,
            charts: newCharts
        }
      },
      updateAllPools: (state, action) => {
        return {
            ...state,
            all: action.payload.data
        }
      },
      clearPools: () => {
          return initialState
      }
  }
})

export const { addPool, addPoolChart, updateAllPools, clearPools } = poolsSlice.actions

export default poolsSlice.reducer